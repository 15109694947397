import { PhaseData } from '../../components/EsForm/ContestPhaseTab';
import { ILike } from '../../redux/account/types';
import { IAttendance } from '../../redux/attendance/types';
import { IHost, IPartner } from '../../redux/discovery/types';
import { AwardCategory, AwardStatus } from '../../redux/festival/types';
import { IScoreCategory } from '../../redux/jury/types';
import { ITalentData } from '../../redux/talent/types';
import { IUserProfile } from '../../redux/user/actions';
import { Follower } from '../../redux/user/model';
import { IView } from '../../redux/view/types';
import { IRole } from './AuditionDetailsEditor.types';
import { ActData } from './Cards.types';
import { IApplication, User } from './ListRender.types';
import { IRegistrationForm } from './forms.types';
import { IContestStatus, Status } from './generic.types';
import { IAdvertisement, IProjectSummary, IVPaid } from './media.types';
import { ROUTE_NAMES } from './routes.types';

export type CurrentProfileType = 'casting_director' | 'talent';

export enum CurrentProfileENUM {
    CASTING_DIRECTOR = 'casting_director',
    TALENT = 'talent',
}
export interface CurrentProfile {
    current_profile: CurrentProfileType;
}

export interface IGetDetails {
    /** The id of the document to be fetched */
    id: string;
}
export interface IUserSummary {
    role: string;
    audme_profile: string;
    _id: string;
    firstName: string;
    lastName: string;
    profile_picture: string;
    id: string;
    userName: string;
    verified?: boolean;
    feature_image?: string;
    visible?: boolean;
    user_type?: string[];
    permissions?: string[];
    feature_image_hash?: string;
    profile_picture_hash?: string;
    gender?: string;
    talent_saved?: boolean;
}

export enum SoundCategory {
    SOUND_TRACK = 'sound_track',
    SOUND_EFFECT = 'sound_effect',
}

export type SoundCategoryType = 'sound_track' | 'sound_effect';
export interface ISoundResponse {
    sound_category: SoundCategoryType;
    created_at: string;
    _id: string;
    title: string;
    sound: string;
    thumbnail: string;
    created_by: IUserSummary;
    createdAt: string;
    updatedAt: string;
    id: string;
    artist: string;
    tag: string;
    status: Status;
    sound_metadata: any;
    duration: number;
    additional_details: any;
}

type Details = {
    stage_name: string;
    user: string;
};

export type Location = {
    type?: string;
    coordinates: Array<number>;
    address?: string;
    description?: string;
};

export type ProductionCompany = {
    id: string;
    location?: Location;
    production_type?: Array<string>;
    gallery?: Array<string>;
    demo_reel?: Array<string>;
    company_name?: string;
    number_of_reviews?: number;
    rating?: number;
    about?: string;
    profile_picture?: string;
    productions?: Array<IAudition>;
    followers?: Array<any>;
    number_of_jobs?: number;
    status?: Status;
};

export type CastingDirector = ProductionCompany;

type ShootLocation = {
    type: string;
    coordinates: Array<number>;
    address: string;
    description: string;
};

export type IAudition = {
    id: string;
    title: string;
    imageURI: string;
    location: string;
    production_company: ProductionCompany;
    synopsis: string;
    total_submissions: number;
    applied: boolean;
    paid: boolean;
    timeToExpiration: number;
    audition_type: string;
    expiration: string;
    applicants: Array<IApplication>;
    created: string;
    attachment: string;
    attachment_type: string;
    attachment_description: string;
    rehearsal_date: string;
    rehearsal_date_unformatted: string;
    tags: Array<string>;
    production_personel: string;
    roles: Array<IRole>;
    interest: number;
    status: Status;
    shoot_location: ShootLocation;
    reward: string;
    reward_frequency: string;
    employment_type: string;
    min_age: string;
    max_age: string;
    required_media: Array<string>;
    required_skill: Array<string>;
    allSubmissions: Array<string>;
    time_created: string;
    created_by: ProductionCompany;
    imageCover?: string;
};

export interface ISavedAudition {
    id: string;
    type: string;
    model: IAudition;
    created_at: Date;
}

interface Params {
    details: Details;
    audition?: IAudition;
    role?: IRole;
}

export const handleOnMessagePress = (navigation: any, payload: Params) => {
    navigation.navigate(ROUTE_NAMES.ACCOUNT, {
        screen: ROUTE_NAMES.ACCOUNT,
        params: {
            details: { ...payload, stage_name: payload.details.stage_name || 'actor', user: payload.details.user },
            audition: { id: payload?.audition },
            role: payload?.role,
        },
    });
    // console.log(payload);
};

export type ChatType =
    | 'chat'
    | 'audition_invite'
    | 'application_invite'
    | 'user_invite'
    | 'media_request'
    | 'video_message'
    | 'picture_message'
    | 'sound_message'
    | 'location_message'
    | 'document_message';
export enum chatType {
    CHAT = 'chat',
    AUDITION_INIVITE = 'audition_invite',
    APPLICATION_INVITE = 'application_invite',
    USER_INVITE = 'user_invite',
    MEDIA_REQUEST = 'media_request',
}

export type ActMode = 'response' | 'reply' | 'demo' | 'share';

export enum ActStatus {
    PUBLISHED = 'published',
    DRAFT = 'draft',
    PUBLIC = 'public',
    CLOSE_FRIENDS = 'close_friends',
    RECRUITERS = 'recruiters',
    PRIVATE = 'private',
}

export interface TalentProfile {
    id: string;
    location: string;
    photos: string[];
    skills: string[];
    demo_reel: Array<ActData>;
    audio_reel: string[];
    age_to: number;
    age_from: number;
    hair_color: string;
    birth_data: string;
    height: string;
    body_type: string;
    sex: string;
    profile_picture: string;
    age_range: string;
    stage_name: string;
    past_work: Array<any>;
    filmography: Array<any>;
    weight: number;
    about: string;
    rating: number;
    number_of_reviews: number;
    feature_picture: string;
}

export interface TalentSearchFilter {
    location: Location;
    age_from: number;
    age_to: number;
    age_range: string;
    sex: string;
    height: number;
    skills: string;
    lng: number;
    lat: number;
    searchTerm: string;
    language_spoken: string;
    hair_color: string;
    body_type: string;
}

export interface IContestPrize {
    created_at: string;
    _id: string;
    category_title: string;
    sub_category?: 'male' | 'female';
    year_started: string;
    contest: string;
    created_by: string;
    id: string;
    prize: string;
}

export interface IContestWatchList {
    created_at: string;
    id: string;
    model: IContest;
    type: string;
}

export type ProjectType =
    | 'short'
    | 'feature'
    | 'script'
    | 'commercial'
    | 'skit'
    | 'act'
    | 'reels'
    | 'pitch'
    | 'poster'
    | 'picture'
    | 'default'
    | 'monologue'
    | 'advertisement'
    | 'impressions'
    | 'dance'
    | 'poetry'
    | 'make up'
    | 'rap'
    | 'music'
    | 'fashiom';

export type JudgingStatus =
    | 'winner'
    | 'semi-finalist'
    | 'finalist'
    | 'selected'
    | 'submitted'
    | 'second'
    | 'third'
    | 'fourth';

export interface ISubmission {
    judging_status: JudgingStatus;
    _id: string;
    project: IProject;
    contest: IContest;
    created_by: string;
    createdAt: string;
    updatedAt: string;
    trending_marker: number;
    id: string;
    host?: IHost;
    partner?: IPartner;
    has_user_voted?: boolean;
    jury_score: IScoreCategory;
    score: INewScore[];
    /** These are used in admin dashboard view */
    all_scores?: number[];
    total_average: number;
}

export interface IDashboardResponse {
    message: string;
    data: IDashboard;
}

export interface IDashboard {
    contest: Contest;
    submission: Submission;
    project: DataProject;
    watch_list: DataWatchList;
}

export interface Contest {
    contest_recommendation: ContestRecommendation[];
    metrics: ContestMetric[];
}

export interface ContestRecommendation {
    _id: string;
    requirements: string[];
    contest_media: string[];
    voting_required: boolean;
    voting_type: string;
    date_start: Date;
    expiration_date: Date;
    contest_phases_enabled: boolean;
    premium: boolean;
    contest_phase: string;
    status: Status;
    title: string;
    description: string;
    created_by: string;
    contest_phase_dates: any[];
    feature_image?: string;
    trending_marker?: number;
    tag: string[];
    __v?: number;
}

export interface ContestMetric {
    _id: string;
    count: number;
    contest: string[];
}

export interface DataProject {
    user_projects: UserProject[];
    metrics: ProjectMetric[];
}

export interface ProjectMetric {
    _id: string;
    count: number;
    project: string[];
}

export interface UserProject {
    _id: string;
    project_type: string;
    project_gallery: any[];
    visible: boolean;
    team: any[];
    status: Status;
    title: string;
    created_by: string;
    project: string;
    feature_image: string;
}

export interface Submission {
    user_submissions: UserSubmission[];
    metrics: ProjectMetric[];
}

export interface UserSubmission {
    _id: string;
    judging_status: string;
    created_by: string;
    project: UserSubmissionProject;
    contest: ContestRecommendation;
}

export interface UserSubmissionProject {
    _id: string;
    project_type: ProjectType;
    project_gallery: string[];
    visible: boolean;
    team: User[];
    status: Status;
    trending_marker: number;
    title: string;
    created_by: string;
    createdAt: Date;
    updatedAt: Date;
    __v: number;
    project: string;
    feature_image: string;
}

export interface DataWatchList {
    watch_list: WatchListElement[];
    metrics: WatchListMetric[];
}

export interface WatchListMetric {
    _id?: null;
    count: number;
}

export interface GenericMetric {
    _id?: any;
    count?: number;
    [key: string]: any;
}

export interface WatchListElement {
    _id: string;
    details: ContestRecommendation;
}

export interface IDashboardModel {
    contest: IContest[];
    project: IProject[];
    submission: ISubmission[];
    watchlist: IContest[];
    metrics: IMetrics;
}

export interface IMetrics {
    contest?: ContestMetric[];
    project?: ProjectMetric[];
    submission?: ProjectMetric[];
    watchlist?: WatchListMetric[];
}

export interface UserSummary {
    _id: string;
    location: Location;
    firstName: string;
    lastName: string;
    userName: string;
    verified: boolean;
    profile_picture?: string;
    user_type?: string[];
    user_follow_interaction?: Follower[];
    user_following?: boolean;
    follows_user?: boolean;
    feature_image_hash?: string;
    profile_picture_hash?: string;
    gender?: 'male' | 'female';
    awards?: IContestAward[];
    talent_saved?: boolean;
    saved_talent?: [ITalentData?]; // Maximum of 1 item
}

/**
 * The below services the initial user signup flow where
 * we have to present to the user people to follow;
 * This is teh expected response from server, matching users
 * interest.
 */
export interface ITopUser extends UserSummary {
    total_audience?: number;
}

export interface IMetaData {
    codec: string;
    duration: number;
    height: number;
    type: string;
    width: number;
}

export interface IProject {
    project_type: ProjectType | string;
    project_format?: 'act' | 'video';
    project_gallery: string[];
    visible: boolean;
    team: IUserSummary[];
    status: Status;
    trending_marker: number;
    total_likes?: number;
    total_anger?: number;
    total_love?: number;
    scheduled_published_date?: string;
    feature_image: string;
    mentions?: User[];
    total_comments?: number;
    view_count?: number;
    last_seen?: string;
    _id: string;
    title: string;
    description: string;
    created_by?: UserSummary;
    createdAt?: string;
    updatedAt?: string;
    project: string;
    submitted_contest?: SubmittedContest[];
    comments?: TComment[];
    id: string;
    video_id?: string;
    media_type?: 'picture' | 'video' | 'audio' | 'document';
    tag?: string[];
    metadata?: IMetaData;
    views?: IView;
    /** Determines when the project media encoding has completed */
    processing_complete?: boolean;
    /** The GIF thumbnail */
    thumbnail?: string;
    /** Determines if a project can be downloaded */
    downloadable?: boolean;
    resolutions?: TResolutions;
    download_link?: string;
    user_like?: ILike[];
    user_view?: IView;
    /** Informs if a user has liked this specific project */
    is_user_liked?: boolean;
    /** Informs if a user has seen this particluar project */
    has_user_seen?: boolean;
    hash?: string;
    /** HSL string, handles link to the master m3u8 */
    hls?: string;
    submissions?: ISubmission[];
    awards?: IContestAward[];
    vroll?: IAdvertisement[];
    vpaid?: IVPaid[];
}

export type TResolutions = {
    '1080p': string;
    '720p': string;
    '480p': string;
    '360p': string;
};

export interface SubmittedContest {
    trending_marker: number;
    judging_status: string;
    _id: string;
    project: TProjectSummary;
    contest: IContest;
    created_by: string;
    createdAt: string;
    updatedAt: string;
    __v: number;
    id: string;
}

export interface Project {
    project_type: string;
    project_gallery: any[];
    visible: boolean;
    team: any[];
    status: Status;
    trending_marker: number;
    total_likes: number;
    total_anger: number;
    total_love: number;
    scheduled_published_date: string;
    mentions: any[];
    total_comments: number;
    view_count: number;
    last_seen: string;
    _id: string;
    title: string;
    description: string;
    created_by: UserSummary;
    createdAt: string;
    updatedAt: string;
    __v: number;
    project: string;
    id: string;
    hash?: string;
}

export type ContestRating = 'G' | 'PG' | 'PG-13' | 'R' | 'NC-17' | 'NR' | 'NR-17';

export type Format = 'act' | 'video';

export type MediaFormat = 'video' | 'audio' | 'picture' | 'document';

export interface ITechnicalSpec {
    min_duration: number;
    max_duration: number;
    format: Format;
    media_type: MediaFormat;
}

export type TContestGenre =
    | 'education'
    | 'edutainment'
    | 'open air casting'
    | 'acting'
    | 'creative advert'
    | 'tv advertisement'
    | 'expressions'
    | 'music'
    | 'artistic expression'
    | 'film festival'
    | 'music festival';

export type TContestPhase =
    | 'preparation' // Before submissions begin
    | 'submission' // Submit Projects
    | 'ideation' // Submit ideas
    | 'pitch' // Submit Pitch
    | 'production' // Go and produce
    | 'judging'
    | 'vote'
    | 'vote'
    | 'none'
    | 'closed'
    | 'event'
    | 'registration';

export interface IContest {
    requirements: string[];
    contest_media: any[];
    voting_required: boolean;
    is_feature: boolean;
    voting_type: string;
    tag: string[];
    date_start: string;
    expiration_date: string;
    contest_phases_enabled: boolean;
    premium: boolean;
    contest_phase: TContestPhase;
    status: Status;
    trending_marker: number;
    total_likes: number;
    total_anger: number;
    total_love: number;
    total_comments: number;
    _id: string;
    title: string;
    description: string;
    created_by: string;
    contest_phase_dates: PhaseData[];
    __v: number;
    feature_image: string;
    id: string;
    prizes?: IContestPrize[];
    submissions?: ISubmission[];
    contest_id: string;
    host?: IHost;
    kind?: 'online' | 'physical';
    partner?: IPartner[];
    rating?: ContestRating;
    technical_spec?: ITechnicalSpec;
    hash?: string;
    genre?: TContestGenre;
    contest_attachment?: string;
    thumbnail?: string;
    submission_email_sent?: boolean;
    vote_email_sent?: boolean;
    winner_email_sent?: boolean;
    contest_open_email_sent?: boolean;
    awards?: IContestAward[];
    score_criteria?: string[];
    mobile_feature_image?: string;
    contest_reference?: string[];
    registration_form?: IRegistrationForm[];
    registration_required?: boolean;
    preferred_name?: string;
    categories?: string[];
    categories_enabled?: boolean;
    playlist?: string[];
    playlists: IThspianPlaylist[];
}

export interface IComment {
    created_at?: string;
    total_likes?: number;
    total_comments?: number;
    _id?: string;
    comment: string;
    project?: TProjectSummary;
    user?: TUserSummary;
    created_by?: string;
    createdAt?: string;
    updatedAt?: string;
    id?: string;
}

export type Likes = 'like' | 'dislike' | 'love' | 'angry';
/** New Convention For Typing */
export type TComment = {
    created_at: string;
    total_likes: number;
    total_love?: number;
    total_anger?: number;
    total_comments?: number;
    total_dislikes?: number;
    _id: string;
    /** Comment text */
    comment: string;
    /** act ID of the comment */
    act?: string;
    /** User */
    user: TUserSummary | IUserSummary | IUserProfile;
    created_by: string;
    updatedAt: string;
    createdAt: string;
    id: string;
    likes?: Array<CommentLike>;
    parent_comment?: string;
    replies?: Array<TComment>;
    project?: TProjectSummary;
    user_like?: CommentLike[];
    is_user_liked?: boolean;
};

export type TUserSummary = {
    role: string;
    audme_profile: string;
    _id: string;
    firstName: string;
    lastName: string;
    email: string;
    profile_picture: string;
    id: string;
    userName?: string;
};

export type CommentLike = {
    created_at: string;
    like_type: Likes;
    _id: string;
    user: string;
    created_by: string;
    comment: string;
};

export type TProjectSummary = {
    id: string;
    _id: string;
};

/** The below will be used for deleting, updating or fetching a doc */
export interface IGenericDocumentModifier {
    /** The Id of the documnet in question */
    documentId: string;
    /** Add an details to help woth modifiying document */
    payload?: any;

    additionalPayload?: any;
    /** Used to redirect when authentication fails */
    redirect?: string;
    morePayload?: any;
}

export interface IGlobalConfiguration {
    audition_filters: AuditionFilters;
    talent_filter: TalentFilter;
    features: Features;
    ring: string;
    default_casting_limit: number;
    default_application_limit: number;
    act_limit: number;
    contest_submission_limit: number;
    client: string;
    _id: string;
    filters: Filters;
    permissions: any[];
    created_by: string;
    use_cache: boolean;
    cache_duration: number;
    media_control: IGlobalConfigurationMediaControl;
    __v: number;
}

export interface IGlobalConfigurationMediaControl {
    file_size_limit: number;
    mobile_size_limit?: number;
    image_size_limit?: number;
    pagination_count: number;
    supported_file_formats: SupportedFileFormat[];
    compressionQualityHigh: number;
    compressionQualityMid: number;
    compressionQualityLow: number;
}

export interface SupportedFileFormat {
    base: string;
    extension: string[];
    _id: string;
}

export interface AuditionFilters {
    rehearsal_date: string;
    required_media: string;
    age_range: string;
    filming_location: string;
    compensation: string;
    audition_type: string;
    required_skill: string;
    tags: string;
    employment_type: string;
}

export interface TalentFilter {
    location: string;
    age_range: string;
    height: string;
    skills: string;
    sex: string;
    hair_color: string;
    body_type: string;
    weight: string;
    language_spoken: string;
    special_skills: string;
}

export interface Features {
    account_features: AccountFeatures;
    voting: string;
    movie_ticket: string;
    audition: string;
    acts: string;
    ads: string;
    contest_emails: string;
    hls_play: string;
}

export interface AccountFeatures {
    movies: string;
    productions: string;
    contributions: string;
    profile: string;
    reviews: string;
    applications: string;
    settings: string;
    people: string;
    tickets: string;
    likes: string;
    boxofix_score: string;
    subscriptions: string;
}

export interface Filters {
    rehearsal_date: string;
    required_media: string;
    age_range: string;
    filming_location: string;
    compensation: string;
    audition_type: string;
}

export enum CONTEST_PHASE {
    AUDIENCE_VOTE = 'vote',
    IDEATION = 'ideation',
    JUDGING = 'judging',
    NONE = 'none',
    PITCH = 'pitch',
    PRODUCTION = 'production',
    SUBMISSION = 'submission',
    CLOSED = 'closed',
    EVENT = 'event',
}

export interface IAdvancedContest {
    _id: string;
    requirements: string[];
    contest_media: any[];
    voting_required: boolean;
    voting_type: string;
    date_start: string;
    expiration_date: string;
    contest_phases_enabled: boolean;
    premium: boolean;
    contest_phase: string;
    status: IContestStatus;
    title: string;
    description: string;
    created_by: string;
    contest_phase_dates: PhaseData[];
    feature_image: string;
    tag: string[];
    trending_marker: number;
    host: IHost;
    vote_stats: VoteStat[];
    total_vote_counted: number;
    submission_stats: SubmissionStat[];
    total_submission: number;
    total_votes: number;
    prizes: Prize[];
    partner: IPartner[];
    top_performers: ISubmission[];
    submission_trend: number[];
    vote_trend: number[];
    contest_id: string;
    submission_email_sent?: boolean;
    vote_email_sent?: boolean;
    winner_email_sent?: boolean;
    contest_open_email_sent?: boolean;
    awards?: IContestAward[];
    categories_enabled: boolean;
    categories: string[];
    total_registration_counted?: number;
    registration?: IAttendance[];
    kind?: 'online' | 'physical';
}

export interface VoteStat {
    _id: string;
    number_of_votes: number;
    vote_rate: number;
}

export interface SubmissionStat {
    _id: string;
    number_of_submissions: number;
    submission_rate: number;
}

export interface Prize {
    _id: string;
    created_at: string;
    category_title: string;
    year_started: string;
    contest: string;
    created_by: string;
    sub_category?: 'male' | 'female';
    __v: number;
}

export enum ECONTEST_PHASE {
    PREPARATIION = 'preparation',
    SUBMISSION = 'submission',
    IDEATION = 'ideation',
    PITCH = 'pitch',
    PRODUCTION = 'production',
    JUDGING = 'judging',
    AUDIENCE_VOTE = 'vote',
    REGISTRATION = 'registration',
    VOTE = 'vote',
    NONE = 'none',
}

export interface IReedemableCode {
    _id: string;
    used: boolean;
    code: string;
    expiration_date: string;
    created_by: string;
    contest: string;
    createdAt: string;
    updatedAt: string;
    used_on?: string;
    user: IUserSummary;
}

export interface IContestAward {
    award_status: AwardStatus;
    created_at: string;
    _id: string;
    award_year: string;
    person_awarded: IUserSummary;
    feature_image?: string;
    hash?: string;
    award_category: AwardCategory;
    award_project: IProjectSummary;
    contest: IContest;
    created_by: string;
    id: string;
}

export type CreatorLocation = 'others' | 'submissions' | undefined;
export enum ECreatorLocation {
    OTHERS = 'others',
    SUBMISSIONS = 'submisisons',
}

export interface IContestSubmissionsWithVotes {
    _id: string;
    trending_marker: number;
    judging_status: string;
    deleted: boolean;
    contest: string;
    project: IProject;
    created_by: string;
    createdAt: string;
    updatedAt: string;
    total_votes: number;
}

export interface INewScoreCategory {
    _id: string;
    category_title: string;
    tag: string[];
    created_by: string;
    createdAt: string;
    updatedAt: string;
}

export interface INewScore {
    category: string;
    jury: string;
    score: number;
    submission: ISubmission | string;
    created_by: IUserSummary | string;
}

export type TSource = {
    src: string;
    /** "video/mp4" || "application/x-mpegURL" */
    type: string;
    /** "240" || "360" || "480" || "720" */
    res?: string;
    /** res with p e.g 240p */
    label?: string;

    default?: boolean;
};

/** Metadata VTT */
export type TTrack = {
    kind: string;
    src: string;
    srlang?: string;
    label?: string;
    default?: string;
};

export interface IUpNext {
    sources: TSource[];
    poster: string;
    title: string;
    duration: string;
    project: IProject;
}

export interface INuevoPlaylist {
    sources: TSource[];
    title: string;
    thumb: string;
    duration: string;
    url: string;
    track?: TTrack[];
    slideImage?: string;
}

export interface IGetPlaylistResponse {
    results: IThspianPlaylist;
    current_page: number;
    total_pages: number;
    total_items: number;
}

export interface IThspianPlaylist {
    _id: string;
    name: string;
    description: string;
    projects: PlaylistProject[];
    created_by: string | IUserSummary;
    playlist_type: 'video' | 'picture' | 'document' | 'mixed' | 'audio';
    created_at: string;
    playlist_id: string;
    deleted: boolean;
    createdAt: string;
    updatedAt: string;
}

interface PlaylistProject {
    project: IProject;
    position: number;
}

export interface ICreatePlaylist {
    name: string;
    description?: string;
    playlist_type: 'video' | 'picture' | 'document' | 'mixed' | 'audio';
    projects: [PlaylistProject];
}

export interface IUpdatePlaylist {
    /** Id of the playlist */
    id: string;
    projects?: [PlaylistProject];
    position?: number;
    project?: string;
}
