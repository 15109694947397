import { DiscoveryFilter, FilterTypes } from '../../data/filters/discovery.data';
import { Step } from 'react-joyride';
import { CreatorLocation } from '../../types/global/helper';

export const types = {
    SET_PAGE: 'SET_PAGE',
    SET_APP_LOADING: 'SET_APP_LOADING',
    TOGGLE_APP_DRAWER: 'TOGGLE_APP_DRAWER',
    SET_FILTER: 'SET_FILTER',
    SET_FILTER_SUB_CATEGORY: 'SET_FILTER_SUB_CATEGORY',
    CHANGE_FILTER_SELECTION: 'CHANGE_FILTER_SELECTION',
    SET_FILTER_SUB_SELECTION: 'SET_FILTER_SUB_SELECTION',
    CLEAR_FILTER: 'CLEAR_FILTER',
    TOGGLE_FILTER: 'TOGGLE_FILTER',
    TOGGLE_NOTIFICATION: 'TOGGLE_NOTIFICATION',
    TOGGLE_ACCOUNT_MODAL: 'TOGGLE_ACCOUNT_MODAL',
    TOGGLE_CREATOR_MODAL: 'TOGGLE_CREATOR_MODAL',
    TOGGLE_COOKIES_DRAWER: 'TOGGLE_COOKIES_DRAWER',
    TOGGLE_TOAST: 'TOGGLE_TOAST',

    SET_SHOW_GUIDE: 'SET_SHOW_GUIDE',

    SET_GUIDE_LINK: 'SET_GUIDE_LINK',

    SET_SHOW_SEARCH_BOX: 'SET_SHOW_SEARCH_BOX',

    SET_SHOW_PROJECT_CREATOR_GUIDE: 'SET_SHOW_PROJECT_CREATOR_GUIDE',

    SET_SHOW_PROJECT_SUGGESTION_BOX: 'SET_SHOW_PROJECT_SUGGESTION_BOX',

    SET_SHARE_MODAL_STATUS: 'SET_SHARE_MODAL_STATUS',

    RESET_REDIRECT: 'RESET_REDIRECT',

    /** Set Whether the project guide is shown */
    SET_PROJECT_GUIDE: 'SET_PROJECT_GUIDE',

    /** Get the value of the project guide */
    GET_PROJECT_GUIDE: 'GET_PROJECT_GUIDE',
};

export type CreatorType = 'project' | 'contest' | undefined;
export interface IToggleCreator {
    creatorType: CreatorType;
    location?: CreatorLocation;
}

export interface IToastPayload {
    state: boolean;
    message: string;
}

export interface IChangeFilterSelection {
    category: string;
    subCategory: string;
    value: string;
}

export interface IToggleNotification {
    on: boolean;
}
export interface IToggleCookiesDrawer {
    on: boolean;
}
export interface IToggleAccountModal {
    on: boolean;
}

export interface ISetFilterPayload {
    category: FilterTypes;
    categoryPayload: DiscoveryFilter[];
}

export interface ISetFilterSubCategoryId {
    id: number | null;
}

export type SwitchStatus = 'on' | 'off';
export interface IFilterPayload {
    filterStatus: SwitchStatus;
    filterId?: number;
}

export type DrawerStatus = 'open' | 'closed';

export type FilterValueSet = {
    [id: number]: {
        value: string | boolean | number;
        displayName?: string;
        field?: string;
    };
};

export type FilterValueSetPayload = {
    id: number;
    value: string;
    displayName?: string;
    field?: string;
};
export interface ITutorialState {
    run: boolean;
    sidebarOpen?: boolean;
    stepIndex?: number;
    steps: Step[];
}
export type TGuide = {
    page: string;
    details: ITutorialState;
};

export interface IGuidePayload {
    page: string;
    run: boolean;
    steps: Step;
}

export interface IAppConfiguratorInitialState {
    currentPage: number;
    loading: boolean;
    drawerStatus: DrawerStatus;
    showSearchBox: TSwitch;
    selectedFilterCategory: FilterTypes;
    selectedFilterSubCatgory: DiscoveryFilter[];
    selectedFiltersValues: FilterValueSet;
    setFilters: any;
    filterStatus: SwitchStatus;
    filterSubCategoryId?: number | null;
    notificationOpen?: boolean;
    accountModalOpen?: boolean;
    creatorModal?: CreatorType;
    showToast?: boolean;
    toastMessage?: string;
    showGuide?: boolean;
    guide: TGuide[];
    selectedPageGuide?: TGuide;
    showProjectCreatorGuide?: boolean;
    showUserFirstProjectSuggestionBox?: boolean;
    cookiesDrawerOpen: boolean;
    shareModalStatus?: boolean;
    sharePayload?: {
        title: string;
        url: string;
    };
    currentRedirect?: string;
    showProjectGuide?: boolean;
}

export type TSwitch = 'on' | 'off';

export enum FILTER_SWITCH {
    ON = 'on',
    OFF = 'off',
}

export interface ISetPage {
    pageIndex: number;
}

export interface ISetLoading {
    loading: boolean;
}

export interface IToggleDrawer {
    status: DrawerStatus;
}

export enum DRAWER_STATE {
    OPEN = 'open',
    CLOSED = 'closed',
}

export enum CREATOR_MODAL {
    PROJECT = 'project',
    CONTEST = 'contest',
}

export interface ISetGuide {
    showGuide: boolean;
    page?: string;
}

export interface IShareLinks {
    title?: string;
    url?: string;
    status: boolean;
}
