import React from 'react';
import { StyleSheet } from 'react-native';
import { Typography, Box, SxProps, Theme } from '@mui/material';
import { useThemeContext } from '../../hook/useThemeManager';
import { ColorScheme, theme } from '../../constants/theme';
import { FONT_ENUM } from '../../types/global/fonts.types';
import share from '../../asset/images/icons/es_share_icon.svg';
import comment from '../../asset/images/icons/es_message_icon.svg';
import like from '../../asset/images/icons/es_heart_icon.svg';
import { EsPlayButton } from '../EsButtons/EsPlayButton';
import { formatNumberWithK } from '../../utils/AppUtils';
import { EsUserButtonPlus } from '../EsButtons/EsUserButtonPlus';
import { IProject, IUserSummary, UserSummary } from '../../types/global/helper';
import { IActSummary } from '../../types/global/media.types';
import { ThspianAvatar } from '../Avatar/ThspianAvatar';
import CustomLazyLoadImage from '../Image/CustomLazyLoadImage';

type Reaction = 'like' | 'share' | 'comment';
export interface IVerticalProjectThumbnail {
    project: IProject;
    onClickPlay?: (projectId: IActSummary, index?: number) => void;
    onClickReaction?: (reactionType: Reaction, projectId: string) => void;
    onClickHandle?: (user?: UserSummary) => void;
    isUpNextMode?: boolean;
    index?: number;
    sx?: SxProps<Theme>;
    imageStyle?: React.CSSProperties;
}

const VerticalProject: React.FC<IVerticalProjectThumbnail> = ({
    project,
    onClickPlay,
    onClickReaction,
    onClickHandle,
    index,
    isUpNextMode,
    sx,
    imageStyle,
}) => {
    const { themeStyle, device } = useThemeContext();
    const styles = dynamicStyles(themeStyle);

    return (
        <Box
            sx={{
                width: { xs: '45vw', sm: '284px' },
                minWidth: {
                    xs: '40vw',
                    sm: '275px',
                },
                height: {
                    xs: '300px',
                    sm: '458px',
                },
                position: 'relative',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'flex-end',
                borderRadius: '10px',
                border: 'none',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                overflow: 'hidden',
                ...sx,
            }}
            key={project._id}>
            <CustomLazyLoadImage
                effect="blur"
                src={project.thumbnail ?? project.feature_image}
                alt={`${project.title}_image`}
                showGradient
                width={'100%'}
                height={'100%'}
                style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    objectFit: 'cover',
                    height: device.isMobile ? '300px' : '458px',
                    zIndex: -1,
                    ...imageStyle,
                }}
            />
            {/* Play button */}
            <EsPlayButton onClick={() => onClickPlay && onClickPlay(project as unknown as IActSummary, index)} />

            <Box
                style={styles.flexColumn}
                sx={{ gap: '12px', marginLeft: '22px', marginRight: '22px', marginBottom: '26px', zIndex: 2 }}>
                {!isUpNextMode && (
                    <Box style={styles.flexColumn} sx={{ gap: '6px' }}>
                        <Typography style={styles.textHandle}>{project.title}</Typography>
                        <Typography
                            style={{
                                display: '-webkit-box',
                                overflow: 'hidden',
                                WebkitBoxOrient: 'vertical',
                                WebkitLineClamp: 3,
                                ...styles.share,
                                ...(device.isMobile && {
                                    display: '-webkit-box',
                                    overflow: 'hidden',
                                    WebkitBoxOrient: 'vertical',
                                    WebkitLineClamp: 1,
                                }),
                            }}>
                            {project.description}
                        </Typography>
                    </Box>
                )}

                {isUpNextMode && (
                    <Box style={styles.flexColumn} sx={{ gap: '6px' }}>
                        <Typography style={styles.upNextHandle}>{project.title}</Typography>
                    </Box>
                )}

                {!isUpNextMode && (
                    <Box style={styles.flexRow} sx={{ width: 'fit-content', height: '42px', gap: '20px' }}>
                        {/* User plus button */}
                        {project && project.created_by && project.created_by.profile_picture ? (
                            <EsUserButtonPlus
                                image={`${project.created_by.profile_picture}`}
                                onClickHandle={() => onClickHandle && onClickHandle(project.created_by)}
                            />
                        ) : (
                            <ThspianAvatar
                                user={project.created_by as unknown as IUserSummary}
                                onClick={() => onClickHandle && onClickHandle(project.created_by)}
                                badgeSx={{
                                    border: `1px solid ${theme[themeStyle].tag_white}`,
                                    borderRadius: '50%',
                                }}
                            />
                        )}
                        <Box
                            style={styles.flexRow}
                            sx={{ width: 'fit-content', height: 'full', gap: { xs: '3px', lg: '15px' } }}>
                            <Box
                                style={styles.shareBox}
                                onClick={() => onClickReaction && onClickReaction('share', project._id)}>
                                <CustomLazyLoadImage src={share} alt="share icon" width={device.isMobile ? 20 : 30} />
                                <Typography style={styles.share}>Share</Typography>
                            </Box>
                            <Box
                                style={styles.shareBox}
                                onClick={() => onClickReaction && onClickReaction('comment', project._id)}>
                                <CustomLazyLoadImage
                                    src={comment}
                                    alt="comment icon"
                                    width={device.isMobile ? 20 : 30}
                                />
                                <Typography style={styles.share}>
                                    {formatNumberWithK(project.total_comments || 0)}
                                </Typography>
                            </Box>
                            <Box
                                style={styles.shareBox}
                                onClick={() => onClickReaction && onClickReaction('like', project._id)}>
                                <CustomLazyLoadImage src={like} alt="heart icon" width={device.isMobile ? 20 : 30} />
                                <Typography style={styles.share}>
                                    {formatNumberWithK(project.total_likes || 0)}
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                )}
            </Box>
        </Box>
    );
};

const dynamicStyles = (colorScheme: ColorScheme) =>
    StyleSheet.create({
        shareBox: {
            width: 'fit-content',
            height: 'full',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
        },
        flexRow: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            flexShrink: 0,
        },
        flexColumn: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            justifyContent: 'center',
        },
        share: {
            fontSize: 12,
            fontWeight: '500',
            fontFamily: FONT_ENUM.MAIN_REGULAR,
            textAlign: 'left',
            color: theme[colorScheme].primary_white,
        },
        textHandle: {
            fontSize: 12,
            fontWeight: '500',
            fontFamily: FONT_ENUM.MAIN_REGULAR,
            textAlign: 'left',
            color: theme[colorScheme].primary_orange,
        },
        upNextHandle: {
            fontSize: 14,
            fontWeight: 'bold',
            fontFamily: FONT_ENUM.REGULAR_HEADER,
            textAlign: 'left',
            color: theme[colorScheme].tag_white,
            textTransform: 'uppercase',
        },
    });

export { VerticalProject };
