import { IErrorType } from '../../constants/types';
import {
    IAdvancedContest,
    IContest,
    IContestAward,
    IContestPrize,
    IGenericDocumentModifier,
    IProject,
    IReedemableCode,
    ISubmission,
} from '../../types/global/helper';
import { IHost, IPartner } from '../discovery/types';
import { IScoreCategory } from '../jury/types';
import {
    IAssignRedeemableCode,
    IAwardCategory,
    IAwardPrize,
    IBookmark,
    ICastVote,
    IContestScoreResponse,
    ICreateAwardCategory,
    ICreateBookmark,
    ICreateContestPayload,
    ICreateHost,
    ICreatePartner,
    ICreateScoreCategory,
    ICreateWinnersEmail,
    IDownloadAsset,
    IGetAllHosts,
    IGetAllPartners,
    IGetAllSubmissions,
    IGetContestPayload,
    IGetContestResponse,
    IGetContests,
    IGetContestsResponse,
    IGetHost,
    IGetPartner,
    IGetRedeemableCodes,
    IGetUnsubmittedProjects,
    IGetUnsubmittedProjectsResponse,
    IGetS3ImagesResponse,
    IGetUserContest,
    IGetUserContests,
    IInitiate2FAResponse,
    IInitiate2FaForVote,
    ISearchPayload,
    ISendContestOpenEmail,
    ISetVotingFlow,
    ISubmisisonModalPayload,
    ISubmitProject,
    IVote,
    TRedeemableCode,
    types,
} from './types';
import { createAction } from 'redux-act';

export const createContest = createAction<ICreateContestPayload>(types.CREATE_CONTEST);
export const createContestSuccess = createAction<IContest>(types.CREATE_CONTEST_SUCCESS);
export const createContestFail = createAction<IErrorType>(types.CREATE_CONTEST_FAIL);

export const getUserContest = createAction<IGetUserContest>(types.GET_USER_CONTEST);
export const getUserContestSuccess = createAction<IAdvancedContest>(types.GET_USER_CONTEST_SUCCESS);
export const getUserContestFail = createAction<IErrorType>(types.GET_USER_CONTEST_FAIL);

export const getUserContests = createAction<IGetUserContests>(types.GET_USER_CONTESTS);
export const getUserContestsSuccess = createAction<IContest[]>(types.GET_USER_CONTESTS_SUCCESS);
export const getUserContestsFail = createAction<IErrorType>(types.GET_USER_CONTESTS_FAIL);

export const updateContest = createAction<IGenericDocumentModifier>(types.UPDATE_CONTEST);
export const updateContestSuccess = createAction<IContest>(types.UPDATE_CONTEST_SUCCESS);
export const updateContestFail = createAction<IErrorType>(types.UPDATE_CONTEST_FAIL);

export const publishContest = createAction<IGenericDocumentModifier>(types.PUBLISH_CONTEST);
export const publishContestSuccess = createAction<IContest>(types.PUBLISH_CONTEST_SUCCESS);
export const publishContestFail = createAction<IErrorType>(types.PUBLISH_CONTEST_FAIL);

export const unPublishContest = createAction<IGenericDocumentModifier>(types.UNPUBLISH_CONTEST);
export const unPublishContestSuccess = createAction<IContest>(types.UNPUBLISH_CONTEST_SUCCESS);
export const unPublishContestFail = createAction<IErrorType>(types.UNPUBLISH_CONTEST_FAIL);

export const deleteContest = createAction<IGenericDocumentModifier>(types.DELETE_CONTEST);
export const deleteContestSuccess = createAction<IGenericDocumentModifier>(types.DELETE_CONTEST_SUCCESS);
export const deleteContestFail = createAction<IErrorType>(types.DELETE_CONTEST_FAIL);

export const createHost = createAction<ICreateHost>(types.CREATE_HOST);
export const createHostSuccess = createAction<IHost>(types.CREATE_HOST_SUCCESS);
export const createHostFail = createAction<IErrorType>(types.CREATE_HOST_FAIL);

export const getHost = createAction<IGetHost>(types.GET_HOST);
export const getHostSuccess = createAction<IHost>(types.GET_HOST_SUCCESS);
export const getHostFail = createAction<IErrorType>(types.GET_HOST_FAIL);

export const getAllHosts = createAction<IGetAllHosts>(types.GET_ALL_HOSTS);
export const getAllHostsSuccess = createAction<IHost[]>(types.GET_ALL_HOSTS_SUCCESS);
export const getAllHostsFail = createAction<IErrorType>(types.GET_ALL_HOSTS_FAIL);

export const deleteHost = createAction<IGenericDocumentModifier>(types.DELETE_HOST);
export const deleteHostSuccess = createAction<IGenericDocumentModifier>(types.DELETE_HOST_SUCCESS);
export const deleteHostFail = createAction<IErrorType>(types.DELETE_HOST_FAIL);

export const updateHost = createAction<IGenericDocumentModifier>(types.UPDATE_HOST);
export const updateHostSuccess = createAction<IGenericDocumentModifier>(types.UPDATE_HOST_SUCCESS);
export const updateHostFail = createAction<IErrorType>(types.UPDATE_HOST_FAIL);

export const createPartner = createAction<ICreatePartner>(types.CREATE_PARTNER);
export const createPartnerSuccess = createAction<IPartner>(types.CREATE_PARTNER_SUCCESS);
export const createPartnerFail = createAction<IErrorType>(types.CREATE_PARTNER_FAIL);

export const getPartner = createAction<IGetPartner>(types.GET_PARTNER);
export const getPartnerSuccess = createAction<IPartner>(types.GET_PARTNER_SUCCESS);
export const getPartnerFail = createAction<IErrorType>(types.GET_PARTNER_FAIL);

export const getAllPartners = createAction<IGetAllPartners>(types.GET_ALL_PARTNERS);
export const getAllPartnersSuccess = createAction<IPartner[]>(types.GET_ALL_PARTNERS_SUCCESS);
export const getAllPartnersFail = createAction<IErrorType>(types.GET_ALL_PARTNERS_FAIL);

export const deletePartner = createAction<IGenericDocumentModifier>(types.DELETE_PARTNER);
export const deletePartnerSuccess = createAction<IGenericDocumentModifier>(types.DELETE_PARTNER_SUCCESS);
export const deletePartnerFail = createAction<IErrorType>(types.DELETE_PARTNER_FAIL);

export const updatePartner = createAction<IGenericDocumentModifier>(types.UPDATE_PARTNER);
export const updatePartnerSuccess = createAction<IGenericDocumentModifier>(types.UPDATE_PARTNER_SUCCESS);
export const updatePartnerFail = createAction<IErrorType>(types.UPDATE_PARTNER_FAIL);

export const getRecommendation = createAction(types.GET_RECOMMENDATION);
export const getRecommendationSuccess = createAction<IContest[]>(types.GET_RECOMMENDATION_SUCCESS);
export const getRecommendationFail = createAction<IErrorType>(types.GET_RECOMMENDATION_FAIL);

export const getBookmark = createAction(types.GET_BOOKMARK);
export const getBookmarkSuccess = createAction<IBookmark[]>(types.GET_BOOKMARK_SUCCESS);
export const getBookmarkFail = createAction<IErrorType>(types.GET_BOOKMARK_FAIL);

export const submitProject = createAction<ISubmitProject>(types.SUBMIT_PROJECT);
export const submitProjectSuccess = createAction<ISubmission>(types.SUBMIT_PROJECT_SUCCESS);
export const submitProjectFail = createAction<IErrorType>(types.SUBMIT_PROJECT_FAIL);

export const rescindSubmission = createAction<ISubmission>(types.RESCIND_SUBMISSION);
export const rescindSubmissionSuccess = createAction<ISubmission>(types.RESCIND_SUBMISSION_SUCCESS);
export const rescindSubmissionFail = createAction<IErrorType>(types.RESCIND_SUBMISSION_FAIL);

export const getMySubmissions = createAction(types.GET_MY_SUBMISSIONS);
export const getMySubmissionsSuccess = createAction<ISubmission[]>(types.GET_MY_SUBMISSIONS_SUCCESS);
export const getMySubmissionsFail = createAction<IErrorType>(types.GET_MY_SUBMISSIONS_FAIL);

export const getContest = createAction<IGetContestPayload>(types.GET_CONTEST);
export const getContestSuccess = createAction<IGetContestResponse>(types.GET_CONTEST_SUCCESS);
export const getContestFail = createAction<IErrorType>(types.GET_CONTEST_FAIL);

export const getContests = createAction<IGetContests>(types.GET_CONTESTS);
export const getContestsSuccess = createAction<IGetContestsResponse>(types.GET_CONTESTS_SUCCESS);
export const getContestsFail = createAction<IErrorType>(types.GET_CONTESTS_FAIL);

export const setSubmissionModalState = createAction<ISubmisisonModalPayload>(types.SET_SUBMISSION_MODAL_STATE);
export const setCampaignForSubmission = createAction<IContest | undefined>(types.SET_CONTEST_FOR_SUBMISSION);
export const resetContestData = createAction(types.RESET_CONTEST_DATA);
export const resetVoteFlow = createAction(types.RESET_VOTE);

export const initaiet2FAForVote = createAction<IInitiate2FaForVote>(types.INITIATE_2FA_FOR_VOTE);
export const initaiet2FAForVoteSuccess = createAction<IInitiate2FAResponse>(types.INITIATE_2FA_FOR_VOTE_SUCCESS);
export const initaiet2FAForVoteFail = createAction<IErrorType>(types.INITIATE_2FA_FOR_VOTE_FAIL);

export const castVote = createAction<ICastVote>(types.VOTE_FOR_SUBMISSION);
export const castVoteSuccess = createAction<IVote>(types.VOTE_FOR_SUBMISSION_SUCCESS);
export const castVoteFail = createAction<IErrorType>(types.VOTE_FOR_SUBMISSION_FAIL);

export const createBookmark = createAction<ICreateBookmark>(types.CREATE_BOOKMARK);
export const createBookmarkSuccess = createAction<IBookmark>(types.CREATE_BOOKMARK_SUCCESS);
export const createBookmarkFail = createAction<IErrorType>(types.CREATE_BOOKMARK_FAIL);

export const deleteBookmark = createAction<IGenericDocumentModifier>(types.DELETE_BOOKMARK);
export const deleteBookmarkSuccess = createAction<IGenericDocumentModifier>(types.DELETE_BOOKMARK_SUCCESS);
export const deleteBookmarkFail = createAction<IErrorType>(types.DELETE_BOOKMARK_FAIL);

export const searchHost = createAction<ISearchPayload>(types.SEARCH_HOST);
export const searchHostSuccess = createAction<IHost[]>(types.SEARCH_HOST_SUCCESS);
export const searchHostFail = createAction<IErrorType>(types.SEARCH_HOST_FAIL);

export const searchPartner = createAction<ISearchPayload>(types.SEARCH_PARTNER);
export const searchPartnerSuccess = createAction<IPartner[]>(types.SEARCH_PARTNER_SUCCESS);
export const searchPartnerFail = createAction<IErrorType>(types.SEARCH_PARTNER_FAIL);

export const createAwardCategory = createAction<ICreateAwardCategory>(types.CREATE_AWARD_CATEGORY);
export const createAwardCategorySuccess = createAction<IAwardCategory>(types.CREATE_AWARD_CATEGORY_SUCCESS);
export const createAwardCategoryFail = createAction<IErrorType>(types.CREATE_AWARD_CATEGORY_FAIL);

export const setContestForEdit = createAction<IContest | undefined>(types.SET_CONTEST_FOR_EDIT);

export const getRedeemableCodes = createAction<IGetRedeemableCodes>(types.GET_REDEEMABLE_CODE);
export const getRedeemableCodesSuccess = createAction<IReedemableCode[]>(types.GET_REDEEMABLE_CODE_SUCCESS);
export const getRedeemableCodesFail = createAction<IErrorType>(types.GET_REDEEMABLE_CODE_FAIL);

export const assignRedeemableCode = createAction<IAssignRedeemableCode>(types.ASSIGN_REDEEMABLE_CODE);
export const assignRedeemableCodeSuccess = createAction<TRedeemableCode>(types.ASSIGN_REDEEMABLE_CODE_SUCCESS);
export const assignRedeemableCodeFail = createAction<IErrorType>(types.ASSIGN_REDEEMABLE_CODE_FAIL);

export const downloadAsset = createAction<IDownloadAsset>(types.DOWNLOAD_ASSET);
export const downloadAssetSuccess = createAction<any>(types.DOWNLOAD_ASSET_SUCCESS);
export const downloadAssetFail = createAction<IErrorType>(types.DOWNLOAD_ASSET_FAIL);

export const setVotingFlow = createAction<ISetVotingFlow>(types.SET_VOTING_FLOW);
export const setVoting2Fa = createAction<boolean>(types.SET_VOTING_2FA);
export const setVoting2FaIndex = createAction<number>(types.SET_VOTING_2FA_STARTING_INDEX);

export const getAwardCategoriesForContest = createAction<IGenericDocumentModifier>(
    types.GET_AWARD_CATEGORIES_FOR_CONTEST,
);
export const getAwardCategoriesForContestSuccess = createAction<IContestPrize[]>(
    types.GET_AWARD_CATEGORIES_FOR_CONTEST_SUCCESS,
);
export const getAwardCategoriesForContestFail = createAction<IErrorType>(types.GET_AWARD_CATEGORIES_FOR_CONTEST_FAIL);

export const getContestAwards = createAction<IGenericDocumentModifier>(types.GET_AWARDED_PRIZES_FOR_CONTEST);
export const getContestAwardsSuccess = createAction<IContestAward[]>(types.GET_AWARDED_PRIZES_FOR_CONTEST_SUCCESS);
export const getContestAwardsFail = createAction<IErrorType>(types.GET_AWARDED_PRIZES_FOR_CONTEST_FAIL);

export const awardPrize = createAction<IAwardPrize>(types.AWARD_PRIZE);
export const awardPrizeSuccess = createAction<IContestAward>(types.AWARD_PRIZE_SUCCESS);
export const awardPrizeFail = createAction<IErrorType>(types.AWARD_PRIZE_FAIL);

export const sendWinnersEmail = createAction<ICreateWinnersEmail>(types.SEND_WINNERS_EMAIL);
export const sendWinnersEmailSucess = createAction(types.SEND_WINNERS_EMAIL_SUCCESS);
export const sendWinnersEmailFail = createAction<IErrorType>(types.SEND_WINNERS_EMAIL_FAIL);

export const sendContestOpenEmail = createAction<ISendContestOpenEmail>(types.SEND_CONTEST_OPEN_EMAIL);
export const sendContestOpenEmailSucess = createAction(types.SEND_CONTEST_OPEN_EMAIL_SUCCESS);
export const sendContestOpenEmailFail = createAction<IErrorType>(types.SEND_CONTEST_OPEN_EMAIL_FAIL);

/** Use the below to set submission prompt for newly created projects */
export const setSubmissionPrompt = createAction<boolean>(types.SET_SUBMISSION_PROMPT);

export const setAutoPublishAndSubmit = createAction<boolean>(types.SET_AUTO_PUBLISH_AND_SUBMIT);

export const getAllSubmissions = createAction<IGetAllSubmissions>(types.GET_ALL_SUBMISSIONS);
export const getAllSubmissionSuccess = createAction<IContestScoreResponse>(types.GET_ALL_SUBMISSIONS_SUCCESS);
export const getAllSubmissionFail = createAction<IErrorType>(types.GET_ALL_SUBMISSIONS_FAIL);

export const createScoreCategory = createAction<ICreateScoreCategory>(types.CREATE_SCORE_CATEGORY);
export const createScoreCategorySuccess = createAction<IScoreCategory[]>(types.CREATE_SCORE_CATEGORY_SUCCESS);
export const createScoreCategoryFail = createAction<IErrorType>(types.CREATE_SCORE_CATEGORY_FAIL);

export const setFocusCampaign = createAction<IContest | undefined>(types.SET_FOCUS_CAMPAIGN);

export const setEmailImage = createAction<string | undefined>(types.SET_EMAIL_IMAGE);

export const getS3images = createAction(types.GET_S3_IMAGES);
export const getS3imagesSuccess = createAction<IGetS3ImagesResponse>(types.GET_S3_IMAGES_SUCCESS);
export const getS3imagesFail = createAction<IErrorType>(types.GET_S3_IMAGES_FAIL);

export const getContestReferences = createAction<string[]>(types.GET_CONTEST_REFERENCES);
export const getContestReferencesSuccess = createAction<IProject[]>(types.GET_CONTEST_REFERENCES_SUCCESS);
export const getContestReferencesFail = createAction<IErrorType>(types.GET_CONTEST_REFERENCES_FAIL);

export const getUsersUnsubmittedProjects = createAction<IGetUnsubmittedProjects>(types.GET_USERS_UNSUBMITTED_PROJECTS);
export const getUsersUnsubmittedProjectsSuccess = createAction<IGetUnsubmittedProjectsResponse>(
    types.GET_USERS_UNSUBMITTED_PROJECTS_SUCCESSS,
);
export const getUsersUnsubmittedProjectsFail = createAction<IErrorType>(types.GET_USERS_UNSUBMITTED_PROJECTS_FAIL);

export const setCategoryForSubmission = createAction<string>(types.SET_CATEGORY_FOR_SUBMISSION);

/** Implement Update CreateScoreCategory */
