import { keys } from 'lodash';
import { ConfigData } from '../constants/types';
import { ECONTEST_PHASE, IComment, IContest, IGlobalConfiguration, IProject } from '../types/global/helper';
import type { Location } from '@remix-run/router';
import { ILike } from '../redux/account/types';
import { getFilteredRoutes, getIdForIbjects } from './AppUtils';
import { APP_ROUTES } from '../types/global/routes.types';
import { IFeature } from '../redux/discovery/types';
import { IBookmark } from '../redux/contest/types';
import { isEmpty } from './lodash';
import { UserPermissionLevels } from '../types/global/user.models';
import { IUserProfile } from '../redux/user/actions';

const featureStates = {
    ACTIVE: 'active',
    INACTIVE: 'inactive',
};

const PATH_NAMES_TO_HIDE_FILTER = ['/profile', '/jury-duty', '/projects', '/submissions', '/settings'];
const PATH_NAMES_TO_SHOW_FILTER = getFilteredRoutes(APP_ROUTES, PATH_NAMES_TO_HIDE_FILTER);
type Feature = 'ads' | 'audition' | 'movie_ticket' | 'voting' | 'contest_emails' | 'hls_play';

export class UserGlobalConfiguration {
    public static IsFeatureEnabled(userGlobalConfiguration: ConfigData, featureName: Feature): boolean {
        return userGlobalConfiguration && userGlobalConfiguration[featureName]
            ? userGlobalConfiguration[featureName] === featureStates.ACTIVE
            : false;
    }

    public static IsFilterEnabled(userGlobalConfiguration: ConfigData, filterName: string): boolean {
        return userGlobalConfiguration && userGlobalConfiguration[filterName]
            ? userGlobalConfiguration[filterName] === featureStates.ACTIVE
            : false;
    }

    public static GetFeatureConfig(userGlobalConfiguration: ConfigData, featureName: string): string {
        return userGlobalConfiguration[featureName];
    }

    public static GetActiveFeatureNames(userGlobalConfiguration: ConfigData): string[] {
        return keys(userGlobalConfiguration).filter(item => userGlobalConfiguration[item] === featureStates.ACTIVE);
    }

    public static GetActiveFilterNames(userGlobalConfiguration: ConfigData): string[] {
        return keys(userGlobalConfiguration).filter(item => userGlobalConfiguration[item] === featureStates.ACTIVE);
    }

    /** Check if project has reached submission limit. Rule is that Each project can only be sed for up to 5 submissions
     * This ensures that the submisison abides by that rule.
     */

    public static CanProjectStillSubmitToContest(project: IProject, configuration?: IGlobalConfiguration): boolean {
        return project.submitted_contest && configuration
            ? project.submitted_contest?.length < configuration.contest_submission_limit &&
                  project.status === 'published'
            : false;
    }

    public static IsAllowedToAccess(): boolean {
        return false;
    }

    /** The bloe will check for the pathname of a page and decide if the filter will hide or be revealed */
    public static shouldShowFiler(location: Location) {
        return PATH_NAMES_TO_SHOW_FILTER.includes(location.pathname);
    }

    public static isAlreadyLiked(likes: ILike[], project?: IProject | IComment) {
        if (!project) return { isLiked: false, like: null };
        const like = likes.find(l => getIdForIbjects(l.project) === project._id);
        if (like) {
            return { isLiked: !!like, like };
        }
        return { isLiked: false, like: null };
    }

    public static isContestOpenToSubmission(contest: IContest | IFeature): boolean {
        return !!contest && contest.contest_phase === ECONTEST_PHASE.SUBMISSION;
    }

    public static isContestInPreparation(contest: IContest | IFeature): boolean {
        return !!contest && contest.contest_phase === ECONTEST_PHASE.PREPARATIION;
    }

    public static isContestAlreadyBookmarked(userBookmarks: IBookmark[], contest: IContest, isSignedIn: boolean) {
        if (!userBookmarks || isEmpty(userBookmarks) || !isSignedIn) return true;
        return userBookmarks.some(bookmark => getIdForIbjects(bookmark.details.model) !== contest._id);
    }

    public static doesUserHaveAccess(user: IUserProfile, access_type: UserPermissionLevels) {
        return user.permissions.includes(access_type);
    }
}
