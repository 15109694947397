import produce from 'immer';
import { types } from './types';
import { types as followTypes } from '../audience/types';
import { IUserInitialState, IUserProfile } from './actions';
import { restoreInitialState } from '../../utils/AppUtils';
import { Like, User } from './model';
import { IFollowThspian, IFollowThspianModel } from '../audience/model';
import { generateTemporaryFollowObject } from '../audience/action';
import { IErrorType } from '../../constants/types';
import { IGenericDocumentModifier } from '../../types/global/helper';
import { types as talentTypes } from '../talent/types';
import { types as userTypes } from '../authentication/types';

const initialState: IUserInitialState = {
    userProfile: {
        firstName: '',
        lastName: '',
        email: '',
        _id: '',
        role: 'regular',
        userName: '',
        phoneNumber: '',
        phone_verified: true,
        email_verified: false,
        profile_picture: '',
        user_profile: {
            id: '',
            skills: [],
            demo_reel: [],
            audio_reel: [],
            about: '',
            birthDay: '',
            age_from: 0,
            age_to: 0,
            hair_color: '',
            weight: '',
            body_type: '',
            photos: [],
        },
        permissions: [],
        audience: [],
        intro: '',
        job_description: '',
        education: '',
        verified: false,
        home_town: {
            coordinates: [],
            type: 'Point',
            address: '',
            description: '',
        },
        total_audience_count: 0,
        application_limit: 0,
        casting_call_limit: 0,
        active_subscription: '',
        projects: [],
        project_activities: [],
        contest_activities: [],
        feature_image: '',
        social: undefined,
    },
    verifyingPhoneNumber: false,
    phoneCodeSent: false,
    phoneCodeFailureSendingErrorMessage: null,
    phoneVerificationComplete: false,
    createUserProfileErrorMessage: null,
    creatingUserProfile: false,
    phoneVerificationFailedErrorMessage: null,
    userSuccessfullyCreated: false,
    validatingFieldValue: false,
    isEmailValid: false,
    emailValidationMessage: null,
    isPhoneNumberValid: false,
    phoneValidationMessage: null,
    emailAndPhoneValidationDone: false,
    emailValidationDone: false,
    phoneValidationDone: false,
    gettingPasswordResetToken: false,
    getPasswordResetTokenMessage: '',
    resettingPassword: false,
    resetPasswordMessage: '',
    gettingUserProfile: false,
    gettingUserProfileErrorMessage: null,
    validatingUsernameValue: false,
    usernameAvailable: false,
    usernameValidationMessage: '',
    usernameValidationDone: false,
    usernameTaken: false,
    usernameSearchErr: false,
    userSearchResult: [],
    searchingUser: false,
    searchingUserErrMsg: null,
    gettingExternalUserProfileErrorMessage: '',
    gettingExternalUserProfile: false,
    updatingUserProfile: false,
    topUsers: [],
    gettingTopUser: false,
    gettingTopUserErrMsg: null,
    testUsers: [],
};

const reducer = (state = initialState, action: any) => {
    const { type, payload } = action;
    switch (type) {
        case types.GET_PHONE_VERIFICATION_CODE:
            return produce(state, draftState => {
                draftState.verifyingPhoneNumber = true;
                draftState.phoneCodeSent = false;
                draftState.phoneCodeFailureSendingErrorMessage = null;
            });
        case types.GET_PHONE_VERIFICATION_CODE_SUCCESS:
            return produce(state, draftState => {
                draftState.verifyingPhoneNumber = false;
                draftState.phoneCodeSent = true;
            });
        case types.GET_PHONE_VERIFICATION_CODE_FAILED:
            return produce(state, draftState => {
                draftState.phoneCodeFailureSendingErrorMessage = payload;
                draftState.phoneCodeSent = false;
                draftState.verifyingPhoneNumber = false;
            });
        case types.CREATE_USER_PROFILE:
            return produce(state, draftState => {
                draftState.creatingUserProfile = true;
            });
        case types.CREATE_USER_PROFILE_FAILED:
            return produce(state, draftState => {
                draftState.createUserProfileErrorMessage = payload;
                draftState.creatingUserProfile = false;
            });
        case types.CREATE_USER_PROFILE_SUCCESS:
            return produce(state, draftState => {
                draftState.userProfile = payload;
                draftState.creatingUserProfile = false;
                draftState.userSuccessfullyCreated = true;
            });
        case types.PHONE_NUMBER_VERIFICATION_SUCCESS:
            return produce(state, draftState => {
                draftState.phoneVerificationComplete = true;
            });
        case types.PHONE_NUMBER_VERIFICATION_FAILED:
            return produce(state, draftState => {
                draftState.phoneVerificationFailedErrorMessage = payload;
            });
        //TODO: remove deprecated field verifications
        case types.START_EMAIL_FIELD_VERIFICATION:
            return produce(state, draftState => {
                draftState.validatingFieldValue = true;
                draftState.emailValidationMessage = null;
            });
        case types.EMAIL_FIELD_VERIFICATION_SUCCESS:
            return produce(state, draftState => {
                draftState.validatingFieldValue = false;
                draftState.emailAndPhoneValidationDone = true;
                draftState.isEmailValid = true;
                draftState.isPhoneNumberValid = true;
            });
        case types.EMAIL_FIELD_VERIFICATION_FAILED:
            return produce(state, draftState => {
                draftState.validatingFieldValue = false;
                draftState.isEmailValid = false;
                draftState.emailValidationMessage = payload;
                draftState.emailAndPhoneValidationDone = true;
                draftState.isEmailValid = false;
                draftState.isPhoneNumberValid = false;
            });
        //TODO: cleanup unused state
        case types.START_EMAIL_FIELD_VERIFICATION2:
            return produce(state, draftState => {
                draftState.validatingFieldValue = true;
                draftState.emailValidationMessage = null;
            });
        case types.EMAIL_FIELD_VERIFICATION_SUCCESS2:
            return produce(state, draftState => {
                draftState.validatingFieldValue = false;
                draftState.emailValidationDone = true;
                draftState.isEmailValid = true;
            });
        case types.EMAIL_FIELD_VERIFICATION_FAILED2:
            return produce(state, draftState => {
                draftState.validatingFieldValue = false;
                draftState.isEmailValid = false;
                draftState.emailValidationMessage = payload;
                draftState.emailValidationDone = true;
                draftState.isEmailValid = false;
            });
        case types.START_PHONE_FIELD_VERIFICATION2:
            return produce(state, draftState => {
                draftState.validatingFieldValue = true;
                draftState.phoneValidationMessage = null;
            });
        case types.PHONE_FIELD_VERIFICATION_SUCCESS2:
            return produce(state, draftState => {
                draftState.validatingFieldValue = false;
                draftState.phoneValidationDone = true;
                draftState.isPhoneNumberValid = true;
            });
        case types.PHONE_FIELD_VERIFICATION_FAILED2:
            return produce(state, draftState => {
                draftState.validatingFieldValue = false;
                draftState.isPhoneNumberValid = false;
                draftState.phoneValidationMessage = payload;
                draftState.phoneValidationDone = true;
            });
        case types.START_USERNAME_FIELD_VERIFICATION:
            return produce(state, draftState => {
                draftState.usernameAvailable = false;
                draftState.validatingUsernameValue = true;
                draftState.usernameValidationMessage = '';
            });
        case types.USERNAME_AVAILABLE:
            return produce(state, draftState => {
                draftState.validatingUsernameValue = false;
                draftState.usernameValidationDone = true;
                draftState.usernameAvailable = true;
                draftState.usernameTaken = false;
                draftState.usernameSearchErr = false;
            });
        case types.USERNAME_TAKEN:
            return produce(state, draftState => {
                draftState.validatingUsernameValue = false;
                draftState.usernameValidationDone = true;
                draftState.usernameAvailable = false;
                draftState.usernameTaken = true;
                draftState.usernameSearchErr = false;
            });
        case types.USERNAME_FIELD_VERIFICATION_FAILED:
            return produce(state, draftState => {
                draftState.validatingUsernameValue = false;
                draftState.usernameAvailable = false;
                draftState.usernameValidationMessage = payload;
                draftState.usernameValidationDone = true;
                draftState.usernameTaken = false;
                draftState.usernameSearchErr = true;
            });
        case types.USERNAME_FIELD_RESET:
            return produce(state, draftState => {
                draftState.usernameAvailable = false;
                draftState.usernameTaken = false;
                draftState.usernameSearchErr = false;
            });
        case types.GET_USER_PROFILE:
            return produce(state, draftState => {
                draftState.gettingUserProfile = true;
            });
        case talentTypes.SAVE_TALENT_SUCCESS:
            return produce(state, draftState => {
                if (draftState.externalUserProfile) {
                    draftState.externalUserProfile!.talent_saved = true;
                    draftState.externalUserProfile?.saved_talent?.push(payload);
                }
            });
        case types.GET_A_USER_PROFILE:
            return produce(state, draftState => {
                const { isRefresh } = payload || {};
                /** This avoids refreshing the dashboard */
                if (!isRefresh) {
                    draftState.gettingExternalUserProfile = true;
                }
            });
        case types.GET_USER_PROFILE_SUCCESS:
            return produce(state, draftState => {
                draftState.userProfile = payload;
                draftState.gettingUserProfile = false;
            });
        case types.GET_A_USER_PROFILE_SUCCESS:
            return produce(state, draftState => {
                draftState.externalUserProfile = payload;
                draftState.gettingExternalUserProfile = false;
            });
        case types.GET_A_USER_PROFILE_FAIL:
            return produce(state, draftState => {
                draftState.gettingExternalUserProfileErrorMessage = payload;
                draftState.gettingExternalUserProfile = false;
            });
        case types.GET_USER_PROFILE_FAILED:
            return produce(state, draftState => {
                draftState.gettingUserProfileErrorMessage = payload;
                draftState.gettingUserProfile = false;
            });
        case types.UPDATE_USER_PROFILE:
            return produce(state, draftState => {
                draftState.updatingUserProfile = true;
            });
        case types.UPDATE_USER_PROFILE_SUCCESS:
            return produce(state, draftState => {
                draftState.updatingUserProfile = false;
                for (const [key, value] of Object.entries(payload)) {
                    (draftState.userProfile as any)[key] = value;
                }
            });
        case types.UPDATE_USER_PROFILE_FAILED:
            return produce(state, draftState => {
                draftState.updatingUserProfile = false;
            });
        case types.RESET_USER_PROFILE:
            return restoreInitialState(state, initialState);
        case types.GET_RESET_PASSWORD_TOKEN:
            return produce(state, draftState => {
                draftState.gettingPasswordResetToken = true;
            });
        case types.GET_RESET_PASSWORD_TOKEN_SUCCESS:
            return produce(state, draftState => {
                draftState.gettingPasswordResetToken = false;
            });
        case types.GET_RESET_PASSWORD_TOKEN_FAILED:
            return produce(state, draftState => {
                draftState.getPasswordResetTokenMessage = payload;
            });
        case types.RESET_PASSWORD:
            return produce(state, draftState => {
                draftState.resettingPassword = true;
            });
        case types.RESET_PASSWORD_SUCCESS:
            return produce(state, draftState => {
                draftState.resettingPassword = false;
            });
        case types.RESET_PASSWORD_FAILED:
            return produce(state, draftState => {
                draftState.resetPasswordMessage = payload;
                draftState.resettingPassword = false;
            });
        case types.CHANGE_PASSWORD:
            return produce(state, draftState => {
                draftState.changingPassword = true;
            });
        case types.CHANGE_PASSWORD_SUCCESS:
            return produce(state, draftState => {
                draftState.changingPassword = false;
            });
        case types.CHANGE_PASSWORD_FAILED:
            return produce(state, draftState => {
                draftState.changingPassword = false;
            });
        case types.SEARCH_USER:
            return produce(state, draftState => {
                draftState.userSearchResult = [];
                draftState.searchingUser = true;
            });
        case types.SEARCH_USER_SUCCESS:
            return produce(state, draftState => {
                draftState.searchingUser = false;
                draftState.userSearchResult = payload;
            });
        case types.SEARCH_USER_FAIL:
            return produce(state, draftState => {
                draftState.searchingUser = false;
                draftState.searchingUserErrMsg = payload;
            });
        case types.ADD_TEST_USER:
            return produce(state, draftState => {
                if (draftState.testUsers.length >= 10) {
                    return;
                }
                draftState.testUsers.push(payload);
            });
        case types.REMOVE_TEST_USER:
            return produce(state, draftState => {
                const { documentId } = payload as IGenericDocumentModifier;
                draftState.testUsers = draftState.testUsers.filter(user => user._id !== documentId);
            });

        case types.GET_CURRENT_USER_PROFILE:
            return produce(state, draftState => {
                draftState.gettingUser = true;
            });
        case types.GET_CURRENT_USER_PROFILE_FAIL:
            return produce(state, draftState => {
                draftState.gettingUserErr = payload;
                draftState.gettingUser = false;
            });
        case types.UPDATE_ACTIVITY_LIKE:
            return produce(state, draftState => {
                const { activity, isExternalProfile } = payload;
                const profile = (isExternalProfile ? 'externalUserProfile' : 'userProfile') as keyof IUserInitialState;
                const like = {} as Like;
                const user = draftState.userProfile;

                like.user = {
                    _id: user._id,
                    userName: user.userName,
                    firstName: user.firstName,
                    lastName: user.lastName,
                    profile_picture: user.profile_picture,
                    verified: user.verified,
                } as User;
                if (activity.activity.type === 'Project') {
                    const activityIndex = (draftState[profile] as IUserProfile).project_activities.findIndex(
                        a => a._id === activity._id,
                    );
                    if (activityIndex !== -1) {
                        (draftState[profile] as IUserProfile).project_activities[
                            activityIndex
                        ].project_activity.user_like = [like];
                        (draftState[profile] as IUserProfile).project_activities[
                            activityIndex
                        ].project_activity.total_likes += 1;
                        (draftState[profile] as IUserProfile).project_activities[activityIndex].updatingLikeStatus =
                            true;
                    }
                } else if (activity.activity.type === 'Contest') {
                    const activityIndex = (draftState[profile] as IUserProfile).contest_activities.findIndex(
                        a => a._id === activity._id,
                    );
                    if (activityIndex > -1) {
                        (draftState[profile] as IUserProfile).contest_activities[
                            activityIndex
                        ].contest_activity.user_like = [like];
                        (draftState[profile] as IUserProfile).contest_activities[
                            activityIndex
                        ].contest_activity.likes.push(like);
                        if (
                            (draftState[profile] as IUserProfile).contest_activities[activityIndex].contest_activity
                                .total_likes < 0
                        ) {
                            (draftState[profile] as IUserProfile).contest_activities[
                                activityIndex
                            ].contest_activity.total_likes = 0;
                        }

                        (draftState[profile] as IUserProfile).contest_activities[
                            activityIndex
                        ].contest_activity.total_likes += 1;

                        (draftState[profile] as IUserProfile).contest_activities[activityIndex].updatingLikeStatus =
                            true;
                    }
                }
            });
        case types.UPDATE_ACTIVITY_LIKE_SUCCESS:
            return produce(state, draftState => {
                const { activity, like, isExternalProfile } = payload;
                const like_category = activity?.activity?.type?.toLowerCase();
                const userId = draftState.userProfile._id;
                const profile = (isExternalProfile ? 'externalUserProfile' : 'userProfile') as keyof IUserInitialState;
                if (like_category === 'project') {
                    const activityIndex = (draftState[profile] as IUserProfile).project_activities.findIndex(
                        a => a._id === activity._id,
                    );
                    if (activityIndex !== -1) {
                        (draftState[profile] as IUserProfile).project_activities[activityIndex].updatingLikeStatus =
                            false;
                        const likeIndex = (draftState[profile] as IUserProfile).project_activities[
                            activityIndex
                        ].project_activity.likes.findIndex(l => l.user._id === userId);
                        if (likeIndex !== -1) {
                            (draftState[profile] as IUserProfile).project_activities[
                                activityIndex
                            ].project_activity.user_like = [like];
                            (draftState[profile] as IUserProfile).project_activities[
                                activityIndex
                            ].project_activity.likes[likeIndex] = like;
                        } else {
                            (draftState[profile] as IUserProfile).project_activities[
                                activityIndex
                            ].project_activity.user_like = [like];
                            (draftState[profile] as IUserProfile).project_activities[
                                activityIndex
                            ].project_activity.likes.push(like);
                        }
                    }
                } else if (like_category === 'contest') {
                    const activityIndex = (draftState[profile] as IUserProfile).contest_activities.findIndex(
                        a => a._id === activity._id,
                    );
                    if (activityIndex !== -1) {
                        (draftState[profile] as IUserProfile).contest_activities[activityIndex].updatingLikeStatus =
                            false;
                        const likeIndex = (draftState[profile] as IUserProfile).contest_activities[
                            activityIndex
                        ].contest_activity.likes.findIndex(l => l.user._id === userId);
                        if (likeIndex !== -1) {
                            (draftState[profile] as IUserProfile).contest_activities[
                                activityIndex
                            ].contest_activity.user_like = [like];
                            (draftState[profile] as IUserProfile).contest_activities[
                                activityIndex
                            ].contest_activity.likes[likeIndex] = like;
                        } else {
                            (draftState[profile] as IUserProfile).contest_activities[
                                activityIndex
                            ].contest_activity.user_like = [like];
                            (draftState[profile] as IUserProfile).contest_activities[
                                activityIndex
                            ].contest_activity.likes.push(like);
                        }
                    }
                }
            });
        case types.UPDATE_ACTIVITY_LIKE_FAILED:
            return produce(state, draftState => {
                const { activity, isExternalProfile } = payload;
                const like_category = activity?.activity?.type?.toLowerCase();
                const userId = draftState.userProfile._id;
                if (like_category === 'project') {
                    const profile = (
                        isExternalProfile ? 'externalUserProfile' : 'userProfile'
                    ) as keyof IUserInitialState;
                    const activityIndex = (draftState[profile] as IUserProfile).project_activities.findIndex(
                        a => a._id === activity._id,
                    );
                    if (activityIndex !== -1) {
                        const likes = (draftState[profile] as IUserProfile).project_activities[
                            activityIndex
                        ].project_activity.likes.filter(x => x.user._id !== userId);
                        (draftState[profile] as IUserProfile).project_activities[activityIndex].updatingLikeStatus =
                            false;
                        (draftState[profile] as IUserProfile).project_activities[activityIndex].project_activity.likes =
                            likes;
                        (draftState[profile] as IUserProfile).project_activities[
                            activityIndex
                        ].project_activity.user_like = [];
                        (draftState[profile] as IUserProfile).project_activities[
                            activityIndex
                        ].project_activity.total_likes -= 1;
                    }
                } else if (like_category === 'contest') {
                    const profile = (
                        isExternalProfile ? 'externalUserProfile' : 'userProfile'
                    ) as keyof IUserInitialState;
                    const activityIndex = (draftState[profile] as IUserProfile).contest_activities.findIndex(
                        a => a._id === activity._id,
                    );
                    if (activityIndex !== -1) {
                        const likes = (draftState[profile] as IUserProfile).contest_activities[
                            activityIndex
                        ].contest_activity.likes.filter(x => x.user._id !== userId);
                        (draftState[profile] as IUserProfile).contest_activities[activityIndex].updatingLikeStatus =
                            false;

                        (draftState[profile] as IUserProfile).contest_activities[activityIndex].contest_activity.likes =
                            likes;

                        (draftState[profile] as IUserProfile).contest_activities[
                            activityIndex
                        ].contest_activity.user_like = [];
                        (draftState[profile] as IUserProfile).contest_activities[
                            activityIndex
                        ].contest_activity.total_likes -= 1;
                    }
                }
            });
        case types.REMOVE_ACTIVITY_LIKE:
            return produce(state, draftState => {
                const { activity, isExternalProfile } = payload;
                const profile = (isExternalProfile ? 'externalUserProfile' : 'userProfile') as keyof IUserInitialState;
                const like_category = activity?.activity?.type?.toLowerCase();
                const userId = draftState.userProfile._id;
                if (like_category === 'project') {
                    const activityIndex = (draftState[profile] as IUserProfile).project_activities.findIndex(
                        a => a._id === activity._id,
                    );
                    if (activityIndex > -1) {
                        (draftState[profile] as IUserProfile).project_activities[activityIndex].updatingLikeStatus =
                            true;

                        const likes = (draftState[profile] as IUserProfile).project_activities[
                            activityIndex
                        ].project_activity.likes.filter(x => x.user._id !== userId);

                        (draftState[profile] as IUserProfile).project_activities[activityIndex].project_activity.likes =
                            likes;

                        (draftState[profile] as IUserProfile).project_activities[
                            activityIndex
                        ].project_activity.user_like = [];

                        (draftState[profile] as IUserProfile).project_activities[
                            activityIndex
                        ].project_activity.total_likes =
                            (draftState[profile] as IUserProfile).project_activities[activityIndex].project_activity
                                .total_likes - 1;
                    }
                } else if (like_category === 'contest') {
                    const activityIndex = (draftState[profile] as IUserProfile).contest_activities.findIndex(
                        a => a._id === activity._id,
                    );

                    if (activityIndex > -1) {
                        const likes = (draftState[profile] as IUserProfile).contest_activities[
                            activityIndex
                        ].contest_activity.likes.filter(x => x.user._id !== userId);

                        (draftState[profile] as IUserProfile).contest_activities[activityIndex].contest_activity.likes =
                            likes;

                        (draftState[profile] as IUserProfile).contest_activities[activityIndex].updatingLikeStatus =
                            true;
                        (draftState[profile] as IUserProfile).contest_activities[
                            activityIndex
                        ].contest_activity.user_like = [];
                        (draftState[profile] as IUserProfile).contest_activities[
                            activityIndex
                        ].contest_activity.total_likes =
                            (draftState[profile] as IUserProfile).contest_activities[activityIndex].contest_activity
                                .total_likes - 1;
                    }
                }
            });
        case types.REMOVE_ACTIVITY_LIKE_SUCCESS:
            return produce(state, draftState => {
                const { activity, isExternalProfile } = payload;
                const profile = (isExternalProfile ? 'externalUserProfile' : 'userProfile') as keyof IUserInitialState;
                const like_category = activity?.activity?.type?.toLowerCase();
                if (like_category === 'project') {
                    const activityIndex = (draftState[profile] as IUserProfile).project_activities.findIndex(
                        a => a._id === activity._id,
                    );
                    if (activityIndex !== -1) {
                        (draftState[profile] as IUserProfile).project_activities[activityIndex].updatingLikeStatus =
                            false;
                    }
                } else if (like_category === 'contest') {
                    const activityIndex = (draftState[profile] as IUserProfile).contest_activities.findIndex(
                        a => a._id === activity._id,
                    );
                    if (activityIndex !== -1) {
                        (draftState[profile] as IUserProfile).contest_activities[activityIndex].updatingLikeStatus =
                            false;
                    }
                }
            });
        case types.REMOVE_ACTIVITY_LIKE_FAILED:
            return produce(state, draftState => {
                const { activity, isExternalProfile, like } = payload;
                const profile = (isExternalProfile ? 'externalUserProfile' : 'userProfile') as keyof IUserInitialState;
                if (activity.activity.type === 'Project') {
                    const activityIndex = (draftState[profile] as IUserProfile).project_activities.findIndex(
                        a => a._id === activity._id,
                    );
                    if (activityIndex !== -1) {
                        (draftState[profile] as IUserProfile).project_activities[activityIndex].updatingLikeStatus =
                            false;
                        (draftState[profile] as IUserProfile).project_activities[
                            activityIndex
                        ].project_activity.user_like = [like];
                        (draftState[profile] as IUserProfile).project_activities[
                            activityIndex
                        ].project_activity.total_likes += 1;
                    }
                } else if (activity.activity.type === 'Contest') {
                    const activityIndex = (draftState[profile] as IUserProfile).contest_activities.findIndex(
                        a => a.contest_activity._id === activity.contest_activity._id,
                    );
                    if (activityIndex !== -1) {
                        (draftState[profile] as IUserProfile).contest_activities[activityIndex].updatingLikeStatus =
                            false;
                        (draftState[profile] as IUserProfile).contest_activities[
                            activityIndex
                        ].contest_activity.user_like = [like];
                        (draftState[profile] as IUserProfile).contest_activities[
                            activityIndex
                        ].contest_activity.total_likes += 1;
                    }
                }
            });
        case types.GET_TOP_USER:
            return produce(state, draftState => {
                draftState.gettingTopUser = true;
            });
        case types.GET_TOP_USER_SUCCESS:
            return produce(state, draftState => {
                draftState.topUsers = payload;
                draftState.gettingTopUser = false;
            });
        case types.GET_TOP_USER_FAIL:
            return produce(state, draftState => {
                draftState.gettingTopUser = false;
                draftState.gettingTopUserErrMsg = payload;
            });
        case followTypes.FOLLOW_A_THSPIAN:
            return produce(state, draftState => {
                const { following, from } = payload as IFollowThspian;
                if (
                    from &&
                    from === 'profile' &&
                    draftState.externalUserProfile &&
                    draftState.externalUserProfile._id === following
                ) {
                    const audience = generateTemporaryFollowObject(following);
                    draftState.externalUserProfile.user_following = true;
                    draftState.externalUserProfile.total_audience_count += 1;
                    draftState.externalUserProfile.audience.push(audience);
                }
            });
        case followTypes.FOLLOW_A_THSPIAN_SUCCESS:
            return produce(state, draftState => {
                const { following, from } = payload as IFollowThspianModel;
                if (
                    from === 'profile' &&
                    draftState.externalUserProfile &&
                    draftState.externalUserProfile._id === following._id
                ) {
                    const index = draftState.externalUserProfile.audience.findIndex(
                        audience => audience.optimistic && audience.following._id === following._id,
                    );
                    if (index > -1) {
                        draftState.externalUserProfile.audience[index] = payload;
                        draftState.externalUserProfile.user_follow_interaction?.push(payload);
                    }
                }
            });
        case followTypes.FOLLOW_A_THSPIAN_FAIL:
            return produce(state, draftState => {
                const { documentId } = payload as IErrorType;
                if (draftState.externalUserProfile && draftState.externalUserProfile._id === documentId) {
                    draftState.externalUserProfile.user_following = false;
                    draftState.externalUserProfile.total_audience_count -= 1;
                    draftState.externalUserProfile.audience = draftState.externalUserProfile.audience.filter(
                        audience => audience.following._id !== documentId,
                    );
                }
            });
        case followTypes.UNFOLLOW_A_THSPIAN:
            return produce(state, draft => {
                if (draft.externalUserProfile) {
                    draft.externalUserProfile.user_following = false;
                    draft.externalUserProfile.total_audience_count -= 1;
                }
            });
        case followTypes.UNFOLLOW_A_THSPIAN_SUCCESS:
            return produce(state, draft => {
                const { documentId } = payload as IGenericDocumentModifier;
                if (draft.externalUserProfile) {
                    const filtered = draft.externalUserProfile.audience.filter(audience => audience._id !== documentId);
                    draft.externalUserProfile.audience = filtered;
                    draft.externalUserProfile.user_follow_interaction = [];
                }
            });
        case followTypes.UNFOLLOW_A_THSPIAN_FAIL:
            return produce(state, draft => {
                if (draft.externalUserProfile) {
                    draft.externalUserProfile.user_following = true;
                    draft.externalUserProfile.total_audience_count += 1;
                }
            });
        case types.INITIATE_EMAIL_VERIFY:
            return produce(state, draft => {
                draft.initializingEmailVerification = true;
            });
        case types.INITIATE_EMAIL_VERIFY_SUCCESS:
            return produce(state, draft => {
                draft.initializingEmailVerification = false;
                draft.emailVerificationSentForSession = true;
            });
        case types.INITIATE_EMAIL_VERIFY_FAIL:
            return produce(state, draft => {
                draft.initializingEmailVerification = false;
            });
        case types.VERIFY_EMAIL:
            return produce(state, draft => {
                draft.verifyingEmail = true;
                draft.verifyingEmailErrorMsg = undefined;
            });
        case types.VERIFY_EMAIL_SUCCESS:
            return produce(state, draft => {
                draft.verifyingEmail = false;
            });
        case types.VERIFY_EMAIL_FAIL:
            return produce(state, draft => {
                draft.verifyingEmail = false;
                draft.verifyingEmailErrorMsg = payload;
            });
        case userTypes.LOGIN_SUCCESS:
            return initialState;
        default:
            return state;
    }
};

export { reducer };
