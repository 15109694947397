/* eslint-disable require-yield */
/* eslint-disable no-throw-literal */
import jwtDecode from 'jwt-decode';
import { call, all, put, takeLatest, take, cancel } from 'redux-saga/effects';

import { storeJwt, storeRefreshToken, removeJwt, retrieveJwt, retrieveRefreshToken, removeRefreshToken } from '.';
import {
    setJwt,
    loginSuccess,
    loginFailed,
    get2FACodeSuccess,
    get2FACodeFailed,
    getJwtSuccess,
    getJwtFailed,
    logoutSuccess,
    logoutFailed,
    appLaunchFlowComplete,
} from './action';
import { types } from './types';
import { api } from '../../configurations/api';
import { loginUrl, login2FAVerifyUrl, refreshTokenUrl } from '../../configurations/api/url';
import { getGlobalConfig, getUserConfig, resetUserConfig } from '../configuration/action';
import { httpRequest } from '../types';
import { getUserProfileSuccess, resetUserProfile } from '../user/actions';
import { getJWtDetails, showToastMessage } from '../../utils/AppUtils';
import { globalErrorHandler } from '../error/saga';
import { getAccountDetails, resetAudmeProfile } from '../account/actions';
import { ROUTES } from '../../types/global/routes.types';
import { nuke } from '../cache/action';
import { navigate } from '../navigator/action';
import { resetTalentProfile } from '../talentProfile/action';
import { resetAuditionData } from '../audition/actions';
import { resetNotificationData } from '../notifications/action';
import { resetContestData } from '../contest/actions';
import { resetProjectData } from '../project/actions';
import { resetActivityData } from '../activity/actions';
import { resetSubscriptionData } from '../subscription/actions';
import { SentryCapture } from '../../analytics/Sentry';
import { MixPanel } from '../../analytics/Mixpanel';

// const LOCAL_DEV = true; // Turn off before pushing

function* appLaunchFlow(): any {
    try {
        const isSignedIn = yield* getAndSetupAppToken();
        if (isSignedIn) {
            // yield* getUserProfile();
            yield put(appLaunchFlowComplete({ userIsAuthenticated: true }));
            yield put(getGlobalConfig());
            yield put(getAccountDetails());
            yield put(getUserConfig());
            yield put(
                navigate({
                    routes: ROUTES.ESDISCOVERY,
                    skipAuthentication: true,
                }),
            );
        } else {
            yield put(appLaunchFlowComplete({ userIsAuthenticated: false }));
            yield put(
                navigate({
                    routes: ROUTES.ESDISCOVERY,
                    skipAuthentication: true,
                }),
            );
        }
    } catch (error) {
        SentryCapture(error, 'error');
        yield put(appLaunchFlowComplete({ userIsAuthenticated: false }));
        yield put(
            navigate({
                routes: ROUTES.ESDISCOVERY,
                skipAuthentication: true,
            }),
        );
    }
}

function* get2FACodeForLogin({ payload, resolve, reject }: any): any {
    try {
        const response = yield call(api, loginUrl, 'POST', payload, 0, 1000);
        const { data, token, refreshToken } = response;
        /** User doesn't need 2fa, because we know their location already */
        if (data && token && refreshToken) {
            yield parseLoginResponse(response);
            yield put(getUserConfig());
            yield put(getAccountDetails());
            resolve({ message: 'done', user: data?.user ?? data });
        } else {
            const { message } = response;
            const medium = message === 'Enter Code sent to your email' ? 'email' : 'WhatsApp';
            const pre = medium === 'email' ? 'We see you are having troubles with your Whatsapp. ' : '';
            yield put(get2FACodeSuccess());
            if (payload.resendToken)
                yield call(showToastMessage, `${pre}A new code has been sent to your ${medium}.`, 'success');
            resolve(message);
        }
    } catch (error: any) {
        SentryCapture(error, 'error');
        yield call(globalErrorHandler, error);
        yield put(get2FACodeFailed(error));
        yield* logout(true);
        reject(error);
    }
}
// TODO: loginSuccess is being called in a lot of redundant places
function* parseLoginResponse(response: any) {
    const { token, refreshToken, data } = response;
    const parsedProfile = data.user;
    yield put(getUserProfileSuccess(parsedProfile));
    MixPanel.identify(parsedProfile._id);
    MixPanel.people.set({
        name: `${parsedProfile.firstName} ${parsedProfile.lastName}`,
        email: parsedProfile.email,
    });
    yield all([
        call(storeJwt, token),
        call(storeRefreshToken, refreshToken),
        put(setJwt({ token, refreshToken })),
        put(loginSuccess(token)),
    ]);
}

function* login({ payload, resolve, reject }: any): any {
    const request = payload;
    try {
        const response = yield call(api, login2FAVerifyUrl, 'POST', request);
        const { data } = response;
        yield parseLoginResponse(response);
        yield put(getUserConfig());
        yield put(getAccountDetails());
        yield put(getUserConfig());
        resolve(data);
    } catch (error: any) {
        yield call(SentryCapture, error, 'error');
        yield call(globalErrorHandler, error);
        yield put(loginFailed(error));
        yield* logout(true);
        reject(error);
    }
}

function* getJwtType(jwt: any) {
    if (jwt) {
        const decodedHeader: any = jwtDecode(jwt, { header: true });
        if (decodedHeader && decodedHeader.alg && decodedHeader.alg === 'HS256') {
            return true;
        }
    }
    return false;
}

export function* getJwt(): any {
    let token;
    let refreshToken;
    try {
        token = yield call(retrieveJwt);
        refreshToken = yield call(retrieveRefreshToken);
        if (token) yield put(getJwtSuccess());
        if (refreshToken && token) yield put(setJwt({ token, refreshToken }));
        return { token, refreshToken };
    } catch (error: any) {
        SentryCapture(error, 'error');
        yield put(getJwtFailed(error));
    }
    return { token: null, refreshToken: null };
}

function* tokenRefreshRequired(jwt: any) {
    const { exp } = jwtDecode(jwt) as any;
    const result = new Date(exp * 1000) <= new Date(Date.now());
    return result;
}

export function* refreshJWT(refresh: string): any {
    try {
        const response = yield call(api, refreshTokenUrl, httpRequest.POST, { refreshToken: refresh }, 5, true);
        const { token, refreshToken } = response;
        console.log(response, 'WHAT RESPONSE DO WE HAVE HEREEEEE');
        return token && refreshToken ? { token, refreshToken } : null;
    } catch (error) {
        SentryCapture(error, 'error');
        return null;
    }
}

function* getAndSetupAppToken(): any {
    let jwtToken;
    let newRefreshToken;

    const { token, refreshToken } = yield* getJwt();
    try {
        if (!token) {
            yield call(SentryCapture, 'No token available', 'error');
            return false;
        } else {
            const oldToken = yield* getJwtType(token);
            if (oldToken) {
                const { isSignedIn } = getJWtDetails(token);
                if (!isSignedIn) {
                    // INFO: THE REFRESH TOKEN IS PASSED IN THE HEADER COOKIE: CHECK API CALL
                    const tokens = yield refreshJWT(refreshToken);
                    if (tokens) {
                        jwtToken = tokens.token;
                        newRefreshToken = tokens.refreshToken;
                        yield call(storeJwt, jwtToken);
                        yield call(storeRefreshToken, newRefreshToken);
                        yield put(setJwt({ token: jwtToken, refreshToken: newRefreshToken }));
                        const parsedProfile = tokens.user;
                        yield put(getUserProfileSuccess(parsedProfile));
                    } else {
                        yield call(SentryCapture, 'No reresh token available', 'error');
                        return false;
                    }
                } else {
                    // GUEST TOKEN ROUTE
                }
                return isSignedIn;
            }
            // NEW TOKEN FLOW
            const { isSignedIn } = getJWtDetails(token);
            if (!isSignedIn) {
                const result = tokenRefreshRequired(token);
                if (result) {
                    // GUEST TOKEN ROUTE
                }
                return isSignedIn;
            }
            const result = yield* tokenRefreshRequired(token);
            if (result) {
                const tokens = yield* refreshJWT(refreshToken);
                if (tokens) {
                    jwtToken = tokens.token;
                    newRefreshToken = tokens.refreshToken;
                    yield call(storeJwt, jwtToken);
                    yield call(storeRefreshToken, newRefreshToken);
                    yield put(setJwt({ token: jwtToken, refreshToken: newRefreshToken }));
                    return isSignedIn;
                }
            }
            jwtToken = token;
            newRefreshToken = refreshToken;
            yield call(storeJwt, jwtToken);
            yield call(storeRefreshToken, newRefreshToken);
            yield put(setJwt({ token: jwtToken, refreshToken: newRefreshToken }));
            return isSignedIn;
        }
    } catch (error: any) {
        yield call(SentryCapture, error, 'error');
        yield put(getJwtFailed(error));
        return false;
    }
}

// TODO: Rework this to only call logout success
function* logout(isFromLogin?: boolean) {
    try {
        yield call(removeJwt);
        yield call(removeRefreshToken);
        yield put(logoutSuccess());
        yield put(nuke());
        yield put(resetUserProfile());
        yield put(resetTalentProfile());
        yield put(resetAudmeProfile());
        yield put(resetAuditionData());
        yield put(resetNotificationData());
        yield put(resetUserConfig());
        yield put(resetContestData());
        yield put(resetProjectData());
        yield put(resetActivityData());
        yield put(resetSubscriptionData());
        MixPanel.reset();
        yield put(
            navigate({
                routes: ROUTES.ESLOGIN,
                skipAuthentication: true,
            }),
        );
    } catch (error) {
        yield call(SentryCapture, error, 'error');
        yield put(logoutFailed({ payload: error }));
    }
}

/** ******************************* WACTHERS ************************************** */

function* appLaunchFlowWatcher() {
    yield takeLatest(types.APP_LAUNCH_FLOW, appLaunchFlow);
}

function* loginWatcher(): any {
    const loginTask = yield takeLatest(types.LOGIN, login);
    yield take(types.LOGIN_CANCELLED);
    yield cancel(loginTask);
}

function* get2FACodeForLoginWatcher() {
    yield takeLatest(types.GET_2FA_CODE_FOR_LOGIN, get2FACodeForLogin);
}

function* logoutWatcher() {
    yield takeLatest(types.LOGOUT, logout);
}

export default function* authenticationSaga() {
    yield all([get2FACodeForLoginWatcher(), loginWatcher(), logoutWatcher(), appLaunchFlowWatcher()]);
}
