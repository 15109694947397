import { TPlayerControl } from '../../types/global/generic.types';
import {
    CreatorLocation,
    IMetaData,
    IProject,
    IThspianPlaylist,
    IUserSummary,
    Likes,
    ProjectType,
    TComment,
} from '../../types/global/helper';
import { IActSummary, IProjectSummary } from '../../types/global/media.types';
import { ILike, LikeCategory, TLikeOrigin } from '../account/types';
import { ISubmission } from '../discovery/types';

export const types = {
    CREATE_PROJECT: 'CREATE_PROJECT',
    CREATE_PROJECT_SUCCESS: 'CREATE_PROJECT_SUCCESS',
    CREATE_PROJECT_FAIL: 'CREATE_PROJECT_FAIL',

    GET_PROJECTS: 'GET_PROJECTS',
    GET_PROJECTS_SUCCESS: 'GET_PROJECTS_SUCCESS',
    GET_PROJECTS_FAIL: 'GET_PROJECTS_FAIL',

    GET_PROJECT: 'GET_PROJECT',
    GET_PROJECT_SUCCESS: 'GET_PROJECT_SUCCESS',
    GET_PROJECT_FAIL: 'GET_PROJECT_FAIL',

    DELETE_PROJECT: 'DELETE_PROJECT',
    DELETE_PROJECT_SUCCESS: 'DELETE_PROJECT_SUCCESS',
    DELETE_PROJECT_FAIL: 'DELETE_PROJECT_FAIL',

    PUBLISH_PROJECT: 'PUBLISH_PROJECT',
    PUBLISH_PROJECT_SUCCESS: 'PUBLISH_PROJECT_SUCCESS',
    PUBLISH_PROJECT_FAIL: 'PUBLISH_PROJECT_FAIL',

    UNPUBLISH_PROJECT: 'UNPUBLISH_PROJECT',
    UNPUBLISH_PROJECT_SUCCESS: 'UNPUBLISH_PROJECT_SUCCESS',
    UNPUBLISH_PROJECT_FAIL: 'UNPUBLISH_PROJECT_FAIL',

    UPLOAD_PROJECT_MEDIA: 'UPLOAD_PROJECT_MEDIA',
    UPLOAD_PROJECT_MEDIA_SUCCESS: 'UPLOAD_PROJECT_MEDIA_SUCCESS',
    UPLOAD_PROJECT_MEDIA_FAIL: 'UPLOAD_PROJECT_MEDIA_FAIL',

    UPDATE_PROJECT: 'UPDATE_PROJECT',
    UPDATE_PROJECT_SUCCESS: 'UPDATE_PROJECT_SUCCESS',
    UPDATE_PROJECT_FAIL: 'UPDATE_PROJECT_FAIL',

    GET_MY_PROJECTS: 'GET_MY_PROJECTS',
    GET_MY_PROJECTS_SUCCESS: 'GET_MY_PROJECTS_SUCCESS',
    GET_MY_PROJECTS_FAIL: 'GET_MY_PROJECTS_FAIL',

    TOGGLE_PLAYER: 'TOGGLE_PLAYER',

    SET_MEDIA: 'SET_MEDIA',
    RESET_PROJECT_DATA: 'RESET_PROJECT_DATA',

    GET_PROJECT_COMMENTS: 'GET_PROJECT_COMMENTS',
    GET_PROJECT_COMMENTS_SUCCESS: 'GET_PROJECT_COMMENTS_SUCCESS',
    GET_PROJECT_COMMENTS_FAIL: 'GET_PROJECT_COMMENTS_FAIL',

    GET_WATCH_CONTENT: 'GET_WATCH_CONTENT',
    GET_WATCH_CONTENT_SUCCESS: 'GET_WATCH_CONTENT_SUCCESS',
    GET_WATCH_CONTENT_FAIL: 'GET_WATCH_CONTENT_FAIL',

    GET_ACT: 'GET_ACT',
    GET_ACT_SUCCESS: 'GET_ACT_SUCCESS',
    GET_ACT_FAIL: 'GET_ACT_FAIL',

    GET_ACTS: 'GET_ACTS',
    GET_ACTS_SUCCESS: 'GET_ACTS_SUCCESS',
    GET_ACTS_FAIL: 'GET_ACTS_FAIL',

    GET_COMMENTS_FOR_ACT: 'GET_COMMENTS_FOR_ACT',
    GET_COMMENTS_FOR_ACT_SUCCESS: 'GET_COMMENTS_FOR_ACT_SUCCESS',
    GET_COMMENTS_FOR_ACT_FAIL: 'GET_COMMENTS_FOR_ACT_FAIL',

    UDPATE_PROJECT_FIELD: 'UDPATE_PROJECT_FIELD',

    LIKE_A_PROJECT: 'LIKE_A_PROJECT',
    LIKE_A_PROJECT_SUCCESS: 'LIKE_A_PROJECT_SUCCESS',
    LIKE_A_PROJECT_FAIL: 'LIKE_A_PROJECT_FAIL',

    DELETE_A_LIKE: 'DELETE_A_LIKE',
    DELETE_A_LIKE_SUCCESS: 'DELETE_A_LIKE_SUCCESS',
    DELETE_A_LIKE_FAIL: 'DELETE_A_LIKE_FAIL',

    LEAVE_COMMENT: 'LEAVE_COMMENT',
    LEAVE_COMMENT_SUCCESS: 'LEAVE_COMMENT_SUCCESS',
    LEAVE_COMMENT_FAIL: 'LEAVE_COMMENT_FAIL',

    DELETE_COMMENT: 'DELETE_COMMENT',
    DELETE_COMMENT_SUCCESS: 'DELETE_COMMENT_SUCCESS',
    DELETE_COMMENT_FAIL: 'DELETE_COMMENT_FAIL',

    UPDATE_COMMENT: 'UPDATE_COMMENT',
    UPDATE_COMMENT_SUCCESS: 'UPDATE_COMMENT_SUCCESS',
    UPDATE_COMMENT_FAIL: 'UPDATE_COMMENT_FAIL',

    LIKE_COMMENT: 'LIKE_COMMENT',
    LIKE_COMMENT_SUCCESS: 'LIKE_COMMENT_SUCCESS',
    LIKE_COMMENT_FAIL: 'LIKE_COMMENT_FAIL',

    DELETE_COMMENT_LIKE: 'DELETE_COMMENT_LIKE',
    DELETE_COMMENT_LIKE_SUCCESS: 'DELETE_COMMENT_LIKE_SUCCESS',
    DELETE_COMMENT_LIKE_FAIL: 'DELETE_COMMENT_LIKE_FAIL',

    PROJECT_PROCESSING_STATUS: 'PROJECT_PROCESSING_STATUS',

    SET_PLAYER_QUERY: 'SET_PLAYER_QUERY',

    SET_NEBULA_SUGGESTION: 'SET_NEBULA_SUGGESTION',
    SELECT_NEBULA_SUGGESTION: 'SELECT_NEBULA_SUGGESTION',

    GET_FEED: 'GET_FEED',
    GET_FEED_SUCCESS: 'GET_FEED_SUCCESS',
    GET_FEED_FAIL: 'GET_FEED_FAIL',

    DOWNLOAD_PROJECT: 'DOWNLOAD_PROJECT',
    DOWNLOAD_PROJECT_SUCCESS: 'DOWNLOAD_PROJECT_SUCCESS',
    DOWNLOAD_PROJECT_FAIL: 'DOWNLOAD_PROJECT_FAIL',

    TRIGGER_MEDIA_FORGE: 'TRIGGER_MEDIA_FORGE',
    TRIGGER_MEDIA_FORGE_SUCCESS: 'TRIGGER_MEDIA_FORGE_SUCCESS',
    TRIGGER_MEDIA_FORGE_FAIL: 'TRIGGER_MEDIA_FORGE_FAIL',

    SET_SUBMISSION: 'SET_SUBMISSION',

    RETRY_PROJECT_PROCESSING: 'RETRY_PROJECT_PROCESSING',

    CANCEL_UPLOAD: 'CANCEL_UPLOAD',
    GET_PROJECT_DETAILS: 'GET_PROJECT_DETAILS',
    GET_PROJECT_DETAILS_SUCCESS: 'GET_PROJECT_DETAILS_SUCCESS',
    GET_PROJECT_DETAILS_FAIL: 'GET_PROJECT_DETAILS_FAIL',

    SET_AUTOPLAY: 'SET_AUTOPLAY',

    GET_PROJECT_COOKIE: 'GET_PROJECT_COOKIE',
    GET_PROJECT_COOKIE_SUUCESS: 'GET_PROJECT_COOKIE_SUUCESS',
    GET_PROJECT_COOKIE_FAIL: 'GET_PROJECT_COOKIE_FAIL',

    GET_PLAYLIST: 'GET_PLAYLIST',
    GET_PLAYLIST_SUCCESS: 'GET_PLAYLIST_SUCCESS',
    GET_PLAYLIST_FAIL: 'GET_PLAYLIST_FAIL',

    CREATE_PLAYLIST: 'CREATE_PLAYLIST',
    CREATE_PLAYLIST_SUCCESS: 'CREATE_PLAYLIST_SUCCESS',
    CREATE_PLAYLIST_FAIL: 'CREATE_PLAYLIST_FAIL',

    UPDATE_PLAYLIST: 'UPDATE_PLAYLIST',
    UPDATE_PLAYLIST_SUCCESS: 'UPDATE_PLAYLIST_SUCCESS',
    UPDATE_PLAYLIST_FAIL: 'UPDATE_PLAYLIST_FAIL',

    DELETE_PLAYLIST: 'DELETE_PLAYLIST',
    DELETE_PLAYLIST_SUCCESS: 'DELETE_PLAYLIST_SUCCESS',
    DELETE_PLAYLIST_FAIL: 'DELETE_PLAYLIST_FAIL',

    GET_MY_PLAYLIST: 'GET_MY_PLAYLIST',
    GET_MY_PLAYLIST_SUCCESS: 'GET_MY_PLAYLIST_SUCCESS',
    GET_MY_PLAYLIST_FAIL: 'GET_MY_PLAYLIST_FAIL',

    SET_PLAYLIST: 'SET_PLAYLIST',
};

export type MediaType = 'video' | 'audio' | 'picture' | 'document' | 'act';
type MediaForgeStatus =
    | 'initiated'
    | 'completed'
    | 'failed'
    | 'upload complete'
    | 'awaiting processing'
    | 'dormant'
    | 'ignore';

export interface MediaForgeReport {
    project?: IProject;
    status: MediaForgeStatus;
    toastId?: string;
    mediaForgeMessage?: string;
    progress?: number;
    message?: string;
}

export type TUserIntraction = {
    is_user_liked?: boolean;
    userLike?: ILike[];
    optimistic?: boolean;
};

export interface IProjectInitialState {
    /** List of all available and public projects */
    projects: IProject[];
    gettingProjects: boolean;
    userProjects: IProject[];
    projectErrMsg: any;
    selectedProject?: IProject | null;
    playlist: IProject[];
    playerActive?: boolean;
    selectedMediaType?: MediaType;
    selectedProjectComments: TComment[];
    watchContent?: WatchContent[];
    gettingWatchContent?: boolean;
    gettingWatchContentErrMsg?: any;
    acts?: IActSummary[];
    gettingActs?: boolean;
    actsErrMsg?: any;
    actsPlaylist?: IActSummary[];
    selectedAct?: IActSummary;
    selectedActsComments?: TComment[];
    gettingActsComments?: boolean;
    gettingActsCommentsErrMsg?: any;
    projectInCreation?: ProjectInCreation;
    creatingProject?: boolean;
    currentProjectInCreation?: IProject | undefined;
    loading?: boolean;
    /** The below will take the current submisison that we are viewing the project
     * The idea here is to use the contest and determine if the current project in view
     * is eligible for voting or not
     */
    selectedSubmission?: ISubmission;
    /** The below allows us to determine when we can load more results.
     * Since we paginate our results by `10`, whenever we return a result that has less than `10`
     * We know that it is the end of that query result, so we make this false
     */
    loadMore?: boolean;
    /** The current result page number */
    currentPage?: number;
    /** The query that is currently being used*/
    currentParam?: string;
    /** Will track when more items are being loaded */
    gettingMoreProjects?: boolean;
    /** Tracks playlist item being fetched */
    gettingPlaylist?: boolean;
    /** getting one single project */
    gettingProject?: boolean;
    /**Handles comments */
    gettingProjectComments?: boolean;
    /** Helps in tracking the status of project processing by media Forge */
    mediaForge?: MediaForgeReport;
    deletingLike?: boolean;
    liking?: boolean;
    /** Tracks if a user has specificall  interacted with the current video*/
    userInteraction?: TUserIntraction;
    /** Single Preview: Tells when we can show the playlist to the vertical player */
    isSinglePreview?: boolean;
    /**Sequential */
    /** Sets when Vertical player can go to next Clip after playing the current */
    isSequential?: boolean;
    /** Detects what type of playlist we are playing */
    playlistType?: TPlaylist;
    /**Suggestion, This determines which suggestion we are using */
    nebulaSuggestion: INebulaSuggestion[];
    /** Selected Suggestion */
    selectedSuggestion?: INebulaSuggestion;
    feed: IProject[];
    gettingFeed?: boolean;
    gettingFeedErrMsg: any;
    currentFeedPage?: number;
    currentFeedLimit?: number;
    canLoadMoreFeed?: boolean;
    /** The below is the first project to be watched in feed from which
     * we fetch more related projects
     */
    initialProjectId?: string;
    downloading?: boolean;
    triggeringMediaForge?: boolean;
    /** Allows us to keep track of when a project is retried for processing. The
     * Idea is that user can only retry, once every 5 minutes. This stores the projet Id
     * and time last retried
     */
    retries: Record<string, string>;
    /** Determines where create project is clicked from. Allows us automate submitting to contests */
    location?: CreatorLocation;
    /** Selected project detals */
    selectedProjectDetails?: IProject | IActSummary;
    gettingSelectedProjectDetails?: boolean;
    /**controls autoplay */
    autoplay?: boolean;
    /**Controls The HLS playlist */
    upNextPlaylist?: IProject[];
    /**similar */
    similarPlaylist?: IProject[];
    /** Control the video player */
    verticalPlayer: TPlayerControl;
    horizontalPlayer: TPlayerControl;
    /** The thspian Plylist */
    thspianPlaylist: IThspianPlaylist[];
    currentPlaylist?: IThspianPlaylist;
    playlisCurrenttPage?: number;
    playlistLimit?: number;
    userPlaylist?: IThspianPlaylist[];
    fetchingPlaylist?: boolean;
    creatingPlaylist?: boolean;
    currentPlaylistInCreation?: IThspianPlaylist;
    playlistErrMsg?: any;
    /** Is playlist */
    isPlaylistCollection?: boolean;
}

export type ProjectInCreation = {
    [key: string]: any;
};

export interface IRetryProjectProcesing {
    projectId: string;
    timeRetried: string;
}

export interface IGetSinglePlaylist {
    id: string;
    withNavigate?: boolean;
    /** Allows us to attach ?search=true to create activity for the search term */
    page?: number;
    limit?: number;
    /** Tells if we are fetching this from another screen */
    fromAnotherScreen?: boolean;
    /** Prevent fetching new projects */
    noPlaylistRefetch?: boolean;
    mediaType?: MediaType;
    skipAuthentication?: boolean;
    skipCache?: boolean;
    skipMediaSet?: boolean;
    withFeedFetch?: boolean;
    /** For the new player. Set this as true to avoid refreshing the player
     * This essentially just fetches the project with vote and like values then sets them in details
     * while the video plays
     */
    justFetchDetails?: boolean;
    /** Initial project
     * The below will be used when we do not want to disturb the player
     */
    projectToSet?: IProject;
}

export interface IGetSingleProject {
    id: string;
    withNavigate?: boolean;
    /** Allows us to attach ?search=true to create activity for the search term */
    params?: string;
    /** Tells if we are fetching this from another screen */
    fromAnotherScreen?: boolean;
    /** Prevent fetching new projects */
    noPlaylistRefetch?: boolean;
    mediaType?: MediaType;
    skipAuthentication?: boolean;
    skipCache?: boolean;
    skipMediaSet?: boolean;
    withFeedFetch?: boolean;
    /** For the new player. Set this as true to avoid refreshing the player
     * This essentially just fetches the project with vote and like values then sets them in details
     * while the video plays
     */
    justFetchDetails?: boolean;
    /** Initial project
     * The below will be used when we do not want to disturb the player
     */
    projectToSet?: IProject;
    /** Only set submission */
    onlySubmissions?: boolean;
}

export type TPlaylist = 'playlist' | 'recommendation';

export interface IGetProjects {
    /** Format the following to include the limit and the page example ?limit=10&page=2.
     * If isSubmissionList is true, params must start with :id, ID here being the contest_id
     * it also takes limit & page and filter="watched | liked | voted" sort="trending" | 'createdAt
     */
    params?: string;
    /** The limit we expect. This is defaulted to 10 and we should esnure that */
    limit?: number;
    /** The page number of the results being queries */
    page: number;
    /** This means we are passing params to the query*/
    isFiltered?: boolean;
    /** Tracks if we are fetching playlist projects */
    isPlaylistFetch?: boolean;
    /** Detects if the player should open with the playlist */
    shouldSetMedia?: boolean;
    /** ALlows us set only the fetched playlist, so the screen doesn't refresh
     * With playlist, we do not refresh the screen when we select a new project to play
     */
    playlistType?: TPlaylist;
    /**
     * Check if we are fetching submissions for a specific contest. Ensure that the playlist type is playlist
     */
    isSubmissionList?: boolean;
    /** Allows us set Custom feed items */
    isFeedFetch?: boolean;
}

export interface ICreateProject {
    title: string;
    project_type: ProjectType;
    description?: string;
    tag?: string[];
    scheduled_published_date?: string;
    mentions?: string[];
    media_type?: MediaType;
    project: string;
    feature_image?: string;
    metadata: IMetaData;
    project_file?: File;
}

export interface IUploadProject {
    project: string | File;
    projectId: string;
    feature_image?: string;
    size?: string;
    videoKey?: string;
    isImage?: boolean;
}

export interface IUpdateProject {
    projectId: string;
    body: {
        feature_image?: string;
        [key: string]: any;
    };
    fromUpload?: boolean;
}

/** This is called on GET project response */
export interface IGetAllProjects {
    data: IProject[];
    /** Determines when we populate the playlist instead of the direct project list
     * Determine if this needs to be changed in the future to something more desciptiove
     */
    isFiltered?: boolean;
    /** Checks if result is less than the plimit count */
    loadMore?: boolean;
    currentPage?: number;
    currentParam?: string;
    isPlaylistFetch?: boolean;
    playlistType?: TPlaylist;
    isFeedFetch?: boolean;
}

export interface ITogglePlayer {
    payload: boolean;
}

export interface ISetMedia {
    media: IProject | null | IActSummary;
    mediaType?: MediaType;
    submissionObject?: ISubmission;
    playlistType?: TPlaylist;
    /** */
    clearCustomSuggestion?: boolean;
    setDetailsOnly?: boolean;
    mediaDetails?: IProject | null | IActSummary;
    onlySubmissions?: boolean;
}

export interface IGetComments {
    /** params take the structure as such, ?project=`projectID`&page=`2` */
    params?: string;
    page?: number;
    limit?: number;
}

export interface IProjectComment {
    created_at: string;
    total_likes: number;
    _id: string;
    comment: string;
    /** The project that the comment is being left for.
     * This doesn;t apply in isReply
     */
    project: string;
    user: IUserSummary;
    created_by: IUserSummary;
    createdAt: string;
    updatedAt: string;
    __v: number;
    id: string;
    user_like?: ILike[];
    is_user_liked?: boolean;
    replies: IProjectComment[];
    total_anger?: number;
    total_love?: number;
}

export interface IGetWatchContent {
    category: string;
}

export type WatchContent = {
    categoryTitle: string;
    categoryContent: IProjectSummary[] | IActSummary[];
};

export interface IWatchContent {
    [key: string]: WatchContent;
}

export interface IGetAct {
    id: string;
}

export interface IGetActPlaylist {
    param: string;
}

export interface IGetActResponse {
    acts: IActSummary[];
}

export interface IGetActComments {
    actId: string;
}

export interface IReactToProject {
    projectId: string;
    like_category: LikeCategory;
    like_type: 'like' | 'dislike';
    like_origin?: TLikeOrigin;
    /** For redirect */
    redirect?: string;
}

export interface ILeaveComment {
    project: IProject;
    comment: string;
    /** Use the below to differentiate between comment types  */
    commentType?: 'project' | 'contest' | 'act' | 'user';
    isReply?: boolean;
    commentId?: string;
    parentComment?: TComment;
    created_at?: string | Date;
    commentOrigin?: TLikeOrigin;
}

export interface ILeaveCommentResponse {
    comment: TComment;
    isReply?: boolean;
    parentComment?: TComment;
    commentOrigin?: TLikeOrigin;
}

export interface IReactToComment {
    like_category: 'project' | 'movie' | 'review' | 'act' | 'comment';
    like_type: Likes;
    like_origin?: TLikeOrigin;
    commentId: string;
}

export interface ISetPlayerQuery {
    isSinglePreview?: boolean;
    isSequential?: boolean;
}

export interface INebulaSuggestion {
    title: string;
    parameter: string;
    playlistType?: TPlaylist;
    id: number | string;
    /** Tells if this suggestion is custom example a user playlist.
     * Makes it easier to remove it when we refresh the player
     */
    isCustom?: boolean;
    /** Choose if we are adding new suggestion or selecting */
    isSetType?: 'push' | 'select';
    /**
     * Check if we are fetching submissions for a specific contest. Ensure that the playlist type is playlist
     */
    isSubmissionList?: boolean;
}

export const nebulaSuggestion: INebulaSuggestion[] = [
    {
        id: 0,
        parameter: '?sort=-createdAt&status=published&visible=true',
        title: 'For you',
        playlistType: 'playlist',
    },
    {
        id: 1,
        parameter: '?sort=-trending_marker&status=published&visible=true',
        title: 'Trending',
        playlistType: 'recommendation',
    },
    {
        id: 2,
        parameter: '?status=published&visible=true&project_type=short',
        title: 'Short',
        playlistType: 'recommendation',
    },
    {
        id: 3,
        parameter: '?status=published&visible=true&project_type=commercial',
        title: 'Commercial',
        playlistType: 'recommendation',
    },
    {
        id: 4,
        parameter: '?status=published&visible=true&project_type=monologue',
        title: 'Monologue',
        playlistType: 'recommendation',
    },
];

export interface IGetFeed {
    firstProjectId: string;
    limit?: number;
    page?: number;
    setMedia?: boolean;
    type?: 'act' | 'video';
}

export interface IGetFeedResponse extends IGetFeed {
    data: IProject[];
    canLoadMore?: boolean;
}

export interface DownloadProjectAction {
    payload: string;
    resolve: (value?: any) => void;
    reject: (reason?: any) => void;
}

export interface ITriggerMediaForge {
    projectKey: string;
    size?: string;
    projectId?: string;
    /** Determines if mediaForge will also gnerate a screenshot */
    withImage?: boolean;
}
