// application_activity(pin):false
// campaign_activities(pin):false
// mentions(pin):false
// project_activities(pin):false
// project_recommendation(pin):false
// shared_content(pin):false

import { ITutorialState } from '../Tutorial/Tutorial';

// email(pin):false
// phone(pin):false
// sms(pin):false

interface ISettingsData {
    header: string;
    body: string;
    service_name: string;
    parent_name: string;
}

interface ICookieData {
    name: string;
    description: string;
    title: string;
}

export const settings: ISettingsData[] = [
    {
        header: 'Subscriptions',
        body: "Notify me about activity from the pages I'm watching.",
        service_name: 'application_activity',
        parent_name: 'web_notifications_settings',
    },
    {
        header: 'Recommended projects',
        body: 'Notify me of projects that I might like based on what I watch.',
        service_name: 'project_recommendation',
        parent_name: 'web_notifications_settings',
    },
    {
        header: 'Campaign Recommendations',
        body: 'Notify me about campaigns I might be interested in.',
        service_name: 'campaign_activities',
        parent_name: 'web_notifications_settings',
    },
    {
        header: 'Mentions',
        body: 'Notify me when others mention my name.',
        service_name: 'mentions',
        parent_name: 'web_notifications_settings',
    },
    {
        header: 'Shared content',
        body: 'Notify me when others share my projects.',
        service_name: 'shared_content',
        parent_name: 'web_notifications_settings',
    },
    {
        header: 'Vote Summary',
        body: 'Notify me with daily or weekly updates on my vote counts.',
        service_name: 'vote_activity',
        parent_name: 'web_notifications_settings',
    },
    {
        header: 'Jury Activity',
        body: 'Notify me with new jury activities.',
        service_name: 'jury_activity',
        parent_name: 'web_notifications_settings',
    },
];

export const communication: ISettingsData[] = [
    {
        header: 'Phone',
        body: 'Notify me via Phone when available.',
        service_name: 'phone',
        parent_name: 'web_communication_preference',
    },
    {
        header: 'SMS',
        body: 'Notify me via short message service (SMS).',
        service_name: 'sms',
        parent_name: 'web_communication_preference',
    },
    {
        header: 'Email',
        body: 'Notify me of important events via email.',
        service_name: 'email',
        parent_name: 'web_communication_preference',
    },
];
export const cookiePreference: ICookieData[] = [
    {
        name: 'essential',
        title: 'Essential (Always On)',
        description:
            'These cookies are the backbone of our website, ensuring it functions smoothly and securely. Think of them as the invisible helpers making everything run behind the scenes.',
    },
    {
        name: 'performace',
        title: 'Performance',
        description:
            'Want to help us make the website even better? These cookies track how you use the site, revealing areas for improvement and ultimately creating a smoother and more enjoyable experience for everyone.',
    },
    {
        name: 'functionality',
        title: 'Functionality',
        description:
            'Remember your preferences, keep you logged in, and personalize your journey. These cookies are your friendly website assistants, saving you time and remembering your choices for a tailored experience.',
    },
    {
        name: 'targeting',
        title: 'Targeting/Advertising',
        description:
            "Like seeing relevant ads and offers? These cookies understand your interests and show you content that might pique your curiosity, ensuring you don't miss out on things you might love.",
    },
];
export const appConfig: ISettingsData[] = [
    {
        header: 'Guided Tour',
        body: 'Enable guided tour.',
        service_name: 'show_app_guide',
        parent_name: 'appConfig',
    },
    {
        header: 'Introduction',
        body: 'Show introduction screen.',
        service_name: 'show_introduction',
        parent_name: 'appConfig',
    },
    {
        header: 'Project Guide',
        body: 'Enable project guide',
        service_name: 'show_project_guide',
        parent_name: 'appConfig',
    },
];

export const projectGuideSteps: ITutorialState = {
    run: true,
    steps: [
        {
            content: 'Click the icon to download or edit project',
            disableBeacon: false,
            disableOverlayClose: false,
            hideCloseButton: false,
            hideFooter: false,
            placement: 'right',
            spotlightClicks: true,
            hideBackButton: false,
            styles: {
                options: {
                    zIndex: 2,
                },
            },
            target: '#download-video',
            title: 'Download processed project here',
            showProgress: false,
            showSkipButton: true,
        },
    ],
};

export const videoGuideSteps: ITutorialState = {
    run: true,
    steps: [
        {
            content: 'Click here to watch more videos',
            disableBeacon: true,
            disableOverlayClose: false,
            hideCloseButton: false,
            hideFooter: false,
            placement: 'right',
            spotlightClicks: true,
            hideBackButton: true,
            styles: {
                options: {
                    zIndex: 9999,
                },
            },
            target: '#more-videos',
            title: 'Watch More',
            showProgress: false,
            showSkipButton: true,
            isFixed: false,
        },
    ],
};

export const tutorialSteps: ITutorialState = {
    run: true,
    steps: [
        {
            content: 'Use the menu to navigate Thspian',
            disableBeacon: true,
            disableOverlayClose: true,
            hideCloseButton: false,
            hideFooter: false,
            placement: 'right',
            spotlightClicks: true,
            styles: {
                options: {
                    zIndex: 9998,
                },
            },
            target: '#menu-icon',
            title: 'Navigation',
        },
        {
            content: 'Search for other thespians, projects and contests.',
            disableBeacon: true,
            disableOverlayClose: true,
            hideCloseButton: false,
            hideFooter: false,
            placement: 'left',
            spotlightClicks: false,
            styles: {
                options: {
                    zIndex: 9998,
                },
            },
            target: '#search-bar',
            title: 'Search',
        },
        {
            content:
                'Create projects to showcase your talents. You can enter your projects in contests and win prizes!',
            disableBeacon: true,
            disableOverlayClose: true,
            hideCloseButton: false,
            hideFooter: false,
            placement: 'bottom',
            spotlightClicks: true,
            styles: {
                options: {
                    zIndex: 9998,
                },
            },
            target: '#create-project-icon',
            title: 'Create A Project',
        },
        {
            content: 'View your created projects here.',
            disableBeacon: true,
            disableOverlayClose: true,
            hideCloseButton: false,
            hideFooter: false,
            placement: 'right',
            spotlightClicks: false,
            styles: {
                options: {
                    zIndex: 9998,
                    width: '100%',
                },
            },
            target: '#projects-menu-icon',
            title: 'Projects',
        },
        {
            content: 'Participate in the featured contest to win prizes!',
            disableBeacon: true,
            disableOverlayClose: true,
            hideCloseButton: false,
            hideFooter: false,
            placement: 'auto',
            spotlightClicks: false,
            styles: {
                options: {
                    zIndex: 9998,
                    width: '100%',
                },
            },
            target: '#featured-contest',
            title: 'Featured Contest',
        },
        {
            content: 'View/Update your contest submissions here',
            disableBeacon: true,
            disableOverlayClose: true,
            hideCloseButton: false,
            hideFooter: false,
            placement: 'right',
            spotlightClicks: false,
            styles: {
                options: {
                    zIndex: 9998,
                    width: '100%',
                },
            },
            target: '#submissions-menu-icon',
            title: 'Submissions',
        },
        {
            content: 'View updates on your project, contests and more!',
            disableBeacon: true,
            disableOverlayClose: true,
            hideCloseButton: false,
            hideFooter: false,
            placement: 'auto',
            spotlightClicks: false,
            styles: {
                options: {
                    zIndex: 9998,
                    width: '100%',
                },
            },
            target: '#notificationIcon',
            title: 'Notifications',
        },
        {
            content: 'Click the icon to download project',
            disableBeacon: true,
            disableOverlayClose: false,
            hideCloseButton: false,
            hideFooter: false,
            placement: 'right',
            spotlightClicks: true,
            hideBackButton: false,
            styles: {
                options: {
                    zIndex: -1,
                },
            },
            target: '#download-video',
            title: 'Download processed project here',
            showProgress: false,
            showSkipButton: true,
        },
    ],
};
