import { createAction } from 'redux-act';
import {
    ICreateProject,
    IGetAct,
    IGetActComments,
    IGetActPlaylist,
    IGetAllProjects,
    IGetComments,
    IGetFeed,
    IGetFeedResponse,
    IGetProjects,
    IGetSinglePlaylist,
    IGetSingleProject,
    IGetWatchContent,
    ILeaveComment,
    ILeaveCommentResponse,
    INebulaSuggestion,
    IProjectComment,
    IReactToComment,
    IReactToProject,
    IRetryProjectProcesing,
    ISetMedia,
    ISetPlayerQuery,
    ITogglePlayer,
    ITriggerMediaForge,
    IUpdateProject,
    IUploadProject,
    MediaForgeReport,
    ProjectInCreation,
    WatchContent,
    types,
} from './types';
import {
    ICreatePlaylist,
    IGenericDocumentModifier,
    IGetPlaylistResponse,
    IProject,
    ISubmission,
    IThspianPlaylist,
    IUpdatePlaylist,
    TComment,
} from '../../types/global/helper';
import { IErrorType } from '../../constants/types';
import { IActSummary } from '../../types/global/media.types';
import { ILikeResponse } from '../account/types';

export const getProjects = createAction<IGetProjects>(types.GET_PROJECTS);
export const getProjectsSuccess = createAction<IGetAllProjects>(types.GET_PROJECTS_SUCCESS);
export const getProjectsFail = createAction<IErrorType>(types.GET_PROJECTS_FAIL);

export const getProject = createAction<IGetSingleProject>(types.GET_PROJECT);
export const getProjectSuccess = createAction<IProject>(types.GET_PROJECT_SUCCESS);
export const getProjectFail = createAction<IErrorType>(types.GET_PROJECT_FAIL);

export const getProjectDetails = createAction<IGetSingleProject>(types.GET_PROJECT_DETAILS);
export const getProjectDetailsSuccess = createAction<IProject>(types.GET_PROJECT_DETAILS_SUCCESS);
export const getProjectDetailsFail = createAction<IErrorType>(types.GET_PROJECT_DETAILS_FAIL);

export const createProject = createAction<ICreateProject>(types.CREATE_PROJECT);
export const createProjectSuccess = createAction<IProject>(types.CREATE_PROJECT_SUCCESS);
export const createProjectFail = createAction<IErrorType>(types.CREATE_PROJECT_FAIL);

export const getMyProjects = createAction(types.GET_MY_PROJECTS);
export const getMyProjectsSuccess = createAction<IProject[]>(types.GET_MY_PROJECTS_SUCCESS);
export const getMyProjectsFail = createAction<IErrorType>(types.GET_MY_PROJECTS_FAIL);

export const togglePlayer = createAction<ITogglePlayer>(types.TOGGLE_PLAYER);
export const setmedia = createAction<ISetMedia>(types.SET_MEDIA);

export const getProjectComments = createAction<IGetComments>(types.GET_PROJECT_COMMENTS);
export const getProjectCommentsSuccess = createAction<IProjectComment[]>(types.GET_PROJECT_COMMENTS_SUCCESS);
export const getProjectCommentsFail = createAction<IErrorType>(types.GET_PROJECT_COMMENTS_FAIL);

export const getWatchContent = createAction<IGetWatchContent>(types.GET_WATCH_CONTENT);
export const getWatchContentSuccess = createAction<WatchContent[]>(types.GET_WATCH_CONTENT_SUCCESS);
export const getWatchContentFail = createAction<IErrorType>(types.GET_WATCH_CONTENT_FAIL);

export const getAct = createAction<IGetAct>(types.GET_ACT);
export const getActSuccess = createAction<IActSummary>(types.GET_ACT_SUCCESS);
export const getActFail = createAction<IErrorType>(types.GET_ACT_FAIL);

export const getActComments = createAction<IGetActComments>(types.GET_COMMENTS_FOR_ACT);
export const getActCommentsSuccess = createAction<TComment[]>(types.GET_COMMENTS_FOR_ACT_SUCCESS);
export const getActCommentsFail = createAction<IErrorType>(types.GET_COMMENTS_FOR_ACT_FAIL);

export const getActs = createAction<IGetActPlaylist>(types.GET_ACTS);
export const getActsSuccess = createAction<IActSummary[]>(types.GET_ACTS_SUCCESS);
export const getActsFail = createAction<IErrorType>(types.GET_ACTS_FAIL);

export const updateProjectField = createAction<ProjectInCreation>(types.UDPATE_PROJECT_FIELD);

export const uploadProjectMedia = createAction<IUploadProject>(types.UPLOAD_PROJECT_MEDIA);
export const uploadProjectMediaSuccess = createAction<IProject>(types.UPLOAD_PROJECT_MEDIA_SUCCESS);
export const uploadProjectMediaFail = createAction<IErrorType>(types.UPLOAD_PROJECT_MEDIA_FAIL);

export const updateProject = createAction<IUpdateProject>(types.UPDATE_PROJECT);
export const updateProjectSuccess = createAction<IProject>(types.UPDATE_PROJECT_SUCCESS);
export const updateProjectFail = createAction<IErrorType>(types.UPDATE_PROJECT_FAIL);

export const publishProject = createAction<IGenericDocumentModifier>(types.PUBLISH_PROJECT);
export const publishProjectSuccess = createAction<IProject>(types.PUBLISH_PROJECT_SUCCESS);
export const publishProjectFail = createAction<IErrorType>(types.PUBLISH_PROJECT_FAIL);

export const unPublishProject = createAction<IGenericDocumentModifier>(types.UNPUBLISH_PROJECT);
export const unPublishProjectSuccess = createAction<IProject>(types.UNPUBLISH_PROJECT_SUCCESS);
export const unPublishProjectFail = createAction<IErrorType>(types.UNPUBLISH_PROJECT_FAIL);

export const deleteProject = createAction<IGenericDocumentModifier>(types.DELETE_PROJECT);
export const deleteProjectSuccess = createAction<IGenericDocumentModifier>(types.DELETE_PROJECT_SUCCESS);
export const deleteProjectFail = createAction<IErrorType>(types.DELETE_PROJECT_FAIL);

export const likeProject = createAction<IReactToProject>(types.LIKE_A_PROJECT);
export const likeProjectSuccess = createAction<ILikeResponse>(types.LIKE_A_PROJECT_SUCCESS);
export const likeProjectFail = createAction<IErrorType>(types.LIKE_A_PROJECT_FAIL);

export const deleteLike = createAction<IGenericDocumentModifier>(types.DELETE_A_LIKE);
export const deleteLikeSuccess = createAction<IGenericDocumentModifier>(types.DELETE_A_LIKE_SUCCESS);
export const deleteLikeFail = createAction<IGenericDocumentModifier>(types.DELETE_A_LIKE_FAIL);

export const leaveComment = createAction<ILeaveComment>(types.LEAVE_COMMENT);
export const leaveCommentSuccess = createAction<ILeaveCommentResponse>(types.LEAVE_COMMENT_SUCCESS);
export const leaveCommentFail = createAction<IErrorType>(types.LEAVE_COMMENT_FAIL);

export const deleteComment = createAction<IGenericDocumentModifier>(types.DELETE_COMMENT);
export const deleteCommentSuccess = createAction<IGenericDocumentModifier>(types.DELETE_COMMENT_SUCCESS);
export const deleteCommentFail = createAction<IErrorType>(types.DELETE_COMMENT_FAIL);

export const updateComment = createAction<IGenericDocumentModifier>(types.UPDATE_COMMENT);
export const updateCommentSuccess = createAction<IGenericDocumentModifier>(types.UPDATE_COMMENT_SUCCESS);
export const updateCommentFail = createAction<IErrorType>(types.UPDATE_COMMENT_FAIL);

export const likeComment = createAction<IReactToComment>(types.LIKE_COMMENT);
export const likeCommentSuccess = createAction<ILikeResponse>(types.LIKE_COMMENT_SUCCESS);
export const likeCommentFail = createAction<IErrorType>(types.LIKE_COMMENT_FAIL);

export const deleteCommentLike = createAction<IGenericDocumentModifier>(types.DELETE_COMMENT_LIKE);
export const deleteCommentLikeSuccess = createAction<IGenericDocumentModifier>(types.DELETE_COMMENT_LIKE_SUCCESS);
export const deleteCommentLikeFail = createAction<IErrorType>(types.DELETE_COMMENT_LIKE_FAIL);
export const resetProjectData = createAction(types.RESET_PROJECT_DATA);

/** Called to initiate report at various levels of project processing */
export const mediaForgeWorker = createAction<MediaForgeReport>(types.PROJECT_PROCESSING_STATUS);

export const setPlayerQuery = createAction<ISetPlayerQuery>(types.SET_PLAYER_QUERY);

export const setNebulaSuggestion = createAction<INebulaSuggestion[]>(types.SET_NEBULA_SUGGESTION);
export const selectNebulaSuggestion = createAction<INebulaSuggestion>(types.SELECT_NEBULA_SUGGESTION);

export const getFeed = createAction<IGetFeed>(types.GET_FEED);
export const getFeedSuccess = createAction<IGetFeedResponse>(types.GET_FEED_SUCCESS);
export const getFeedFail = createAction<IErrorType>(types.GET_FEED_FAIL);

export const downloadProject = createAction<string>(types.DOWNLOAD_PROJECT);
export const downloadProjectSuccess = createAction<Blob>(types.DOWNLOAD_PROJECT_SUCCESS);
export const downloadProjectFail = createAction<IErrorType>(types.DOWNLOAD_PROJECT_FAIL);

export const triggerMediaForge = createAction<ITriggerMediaForge>(types.TRIGGER_MEDIA_FORGE);
export const triggerMediaForgeSuccess = createAction(types.TRIGGER_MEDIA_FORGE_SUCCESS);
export const triggerMediaForgeFail = createAction<IErrorType>(types.TRIGGER_MEDIA_FORGE_FAIL);

export const setSubmission = createAction<ISubmission | undefined>(types.SET_SUBMISSION);
export const mediaForgeTrigger = createAction<IRetryProjectProcesing>(types.RETRY_PROJECT_PROCESSING);

export const cancelUpload = createAction(types.CANCEL_UPLOAD);

export const setAutoPlay = createAction<boolean>(types.SET_AUTOPLAY);

export const getProjectCookie = createAction<IGetSingleProject>(types.GET_PROJECT_COOKIE);
export const getProjectCookieSuccess = createAction(types.GET_PROJECT_COOKIE_SUUCESS);
export const getProjectCookieFail = createAction<IErrorType>(types.GET_PROJECT_COOKIE_FAIL);

export const getPlaylist = createAction<IGetSinglePlaylist>(types.GET_PLAYLIST);
export const getPlaylistSuccess = createAction<IGetPlaylistResponse>(types.GET_PLAYLIST_SUCCESS);
export const getPlaylistFail = createAction<IErrorType>(types.GET_PLAYLIST_FAIL);

export const getMyPlaylist = createAction(types.GET_MY_PLAYLIST);
export const getMyPlaylistSuccess = createAction<IThspianPlaylist[]>(types.GET_MY_PLAYLIST_SUCCESS);
export const getMyPlaylistFail = createAction<IErrorType>(types.GET_MY_PLAYLIST_FAIL);

export const createPlaylist = createAction<ICreatePlaylist>(types.CREATE_PLAYLIST);
export const createPlaylistSuccess = createAction<IThspianPlaylist>(types.CREATE_PLAYLIST_SUCCESS);
export const createPlaylistFail = createAction<IErrorType>(types.CREATE_PLAYLIST_FAIL);

export const updatePlaylist = createAction<IUpdatePlaylist>(types.UPDATE_PLAYLIST);
export const updatePlaylistSuccess = createAction<IThspianPlaylist>(types.UPDATE_PLAYLIST_SUCCESS);
export const updatePlaylistFail = createAction<IErrorType>(types.UPDATE_PLAYLIST_FAIL);

export const deletePlaylist = createAction<IGenericDocumentModifier>(types.DELETE_PLAYLIST);
export const deletePlaylistSuccess = createAction<IGenericDocumentModifier>(types.DELETE_PLAYLIST_SUCCESS);
export const deletePlaylistFail = createAction<IErrorType>(types.DELETE_PLAYLIST_FAIL);

export const setPlaylist = createAction<IGetPlaylistResponse>(types.SET_PLAYLIST);
