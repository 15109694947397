import React, { lazy, Suspense } from 'react';
import { Router } from './Router/index.web';
import { ROUTES } from './types/global/routes.types';
import { Navigate } from 'react-router-dom';
import { ProtectedRoute } from './module/ProtectedRoute';
import * as Sentry from '@sentry/react';
import { APILoader } from './pages';
import { Loading } from './components/Loading/Loading';
import { EsMyProfileContainerLoadingState } from './components/ScreenContainer/EsMyProfileContainer/EsMyProfileContainerLoadingState';
import { DiscoveryShimmer } from './pages/DiscoveryShimmer';
import { ViewContestShimmer } from './pages/ViewContestShimmer';
import { TrendingVideosShimmer } from './pages/TrendingVideosShimmer';
import { ComingSoonShimmer } from './pages/ComingSoonShimmer';
import { ActiveContestShimmer } from './pages/ActiveContestShimmer';
import { WatchShimmer } from './pages/WatchShimmer';
import { UserProjectsShimmer } from './pages/UserProjectsShimmer';
import { FooterPageShimmer } from './pages/FooterPageShimmer';
import { JuryPageShimmer } from './pages/JuryPageShimmer';
import { SearchContainerShimmer } from './pages/SearchContainerShimmer';
import { E_USER_PERMISSIONS } from './types/global/user.models';

// const HomePage = en_config.WIP ? Splash : Home;
const EsDiscovery = lazy(() =>
    //@ts-ignore
    import('./pages/EsDiscovery/EsDiscovery').then(module => {
        return { default: module.EsDiscovery };
    }),
);

const AppLayout = lazy(() =>
    //@ts-ignore
    import('./pages').then(module => {
        return { default: module.AppLayout };
    }),
);

const EsLogin = lazy(() =>
    //@ts-ignore
    import('./pages').then(module => {
        return { default: module.EsLogin };
    }),
);

const EsSignup = lazy(() =>
    //@ts-ignore
    import('./pages').then(module => {
        return { default: module.EsSignup };
    }),
);
const ModernPageNotFound = lazy(() =>
    //@ts-ignore
    import('./pages').then(module => {
        return { default: module.ModernPageNotFound };
    }),
);
const PrivacyPolicy = lazy(() =>
    //@ts-ignore
    import('./pages').then(module => {
        return { default: module.PrivacyPolicy };
    }),
);
const HelpCenter = lazy(() =>
    //@ts-ignore
    import('./pages').then(module => {
        return { default: module.HelpCenter };
    }),
);
const FAQ = lazy(() =>
    //@ts-ignore
    import('./pages').then(module => {
        return { default: module.FAQ };
    }),
);
const Search = lazy(() =>
    //@ts-ignore
    import('./pages').then(module => {
        return { default: module.Search };
    }),
);
const Shop = lazy(() =>
    //@ts-ignore
    import('./pages').then(module => {
        return { default: module.Shop };
    }),
);
const TermsOfUse = lazy(() =>
    //@ts-ignore
    import('./pages').then(module => {
        return { default: module.TermsOfUse };
    }),
);
const ErrorBoundary = lazy(() =>
    //@ts-ignore
    import('./pages').then(module => {
        return { default: module.ErrorBoundary };
    }),
);

const EsSettings = lazy(() =>
    //@ts-ignore
    import('./pages/EsSettings').then(module => {
        return { default: module.EsSettings };
    }),
);

const EsMyProfile = lazy(() =>
    //@ts-ignore
    import('./pages/EsMyProfile').then(module => {
        return { default: module.EsMyProfile };
    }),
);

const EsContestEngage = lazy(() =>
    //@ts-ignore
    import('./pages/EsContestEngage').then(module => {
        return { default: module.EsContestEngage };
    }),
);

const EsViewContest = lazy(() =>
    //@ts-ignore
    import('./pages/EsViewContest').then(module => {
        return { default: module.EsViewContest };
    }),
);

const Event = lazy(() =>
    //@ts-ignore
    import('./pages/EsViewContest').then(module => {
        return { default: module.Event };
    }),
);

const EsComingSoon = lazy(() =>
    //@ts-ignore
    import('./pages/EsComingSoon').then(module => {
        return { default: module.EsComingSoon };
    }),
);
const Talents = lazy(() =>
    //@ts-ignore
    import('./pages/Talents').then(module => {
        return { default: module.Talents };
    }),
);

const UserProjects = lazy(() =>
    //@ts-ignore
    import('./pages/UserProjects').then(module => {
        return { default: module.UserProjects };
    }),
);

const EsJoinContest = lazy(() =>
    //@ts-ignore
    import('./pages/EsJoinContest').then(module => {
        return { default: module.EsJoinContest };
    }),
);

const EsLiveContests = lazy(() =>
    //@ts-ignore
    import('./pages/EsLiveContests').then(module => {
        return { default: module.EsLiveContests };
    }),
);

const EsAllContests = lazy(() =>
    //@ts-ignore
    import('./pages/EsAllContests').then(module => {
        return { default: module.EsAllContests };
    }),
);

const EsJuryDuty = lazy(() =>
    //@ts-ignore
    import('./pages/EsJuryDuty').then(module => {
        return { default: module.EsJuryDuty };
    }),
);

const EsActiveContest = lazy(() =>
    //@ts-ignore
    import('./pages/EsActiveContest').then(module => {
        return { default: module.EsActiveContest };
    }),
);

const EsTrendingVideos = lazy(() =>
    //@ts-ignore
    import('./pages/EsTrendingVideos').then(module => {
        return { default: module.EsTrendingVideos };
    }),
);

const EsWatchVideo = lazy(() =>
    //@ts-ignore
    import('./pages/EsWatchVideo').then(module => {
        return { default: module.EsWatchVideo };
    }),
);

const EsNewPassword = lazy(() =>
    //@ts-ignore
    import('./pages/EsNewPassword/EsNewPassword').then(module => {
        return { default: module.EsNewPassword };
    }),
);

const EsResetPassword = lazy(() =>
    //@ts-ignore
    import('./pages/EsResetPassword').then(module => {
        return { default: module.EsResetPassword };
    }),
);

const VerifyEmail = lazy(() =>
    //@ts-ignore
    import('./pages/VerifyEmail').then(module => {
        return { default: module.VerifyEmail };
    }),
);

const JuryAcceptance = lazy(() =>
    //@ts-ignore
    import('./pages/JuryAcceptance').then(module => {
        return { default: module.JuryAcceptance };
    }),
);

const Links = lazy(() =>
    //@ts-ignore
    import('./pages/Links').then(module => {
        return { default: module.Links };
    }),
);

const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(Router);

export const router = sentryCreateBrowserRouter([
    {
        path: ROUTES.HOME,
        element: (
            <Suspense fallback={<Loading />}>
                <AppLayout />
            </Suspense>
        ),
        errorElement: <ModernPageNotFound />,
        children: [
            { index: true, element: <Navigate to={ROUTES.API_LOADER} replace /> },
            {
                path: ROUTES.API_LOADER,
                element: <APILoader />,
                errorElement: <ErrorBoundary />,
            },
            {
                path: ROUTES.ESDISCOVERY,
                element: (
                    <Suspense fallback={<DiscoveryShimmer />}>
                        <EsDiscovery />
                    </Suspense>
                ),
                errorElement: <ErrorBoundary />,
            },
            {
                path: ROUTES.ESWATCH_VIDEO,
                element: (
                    <Suspense fallback={<WatchShimmer />}>
                        <EsWatchVideo />
                    </Suspense>
                ),
                errorElement: <ErrorBoundary />,
            },
            {
                path: ROUTES.ESTRENDING,
                element: (
                    <Suspense fallback={<TrendingVideosShimmer />}>
                        <EsTrendingVideos />
                    </Suspense>
                ),
                errorElement: <ErrorBoundary />,
            },
            {
                path: ROUTES.ESCONTEST,
                element: (
                    <Suspense fallback={<ActiveContestShimmer />}>
                        <EsActiveContest />
                    </Suspense>
                ),
                errorElement: <ErrorBoundary />,
            },
            {
                path: ROUTES.ESALL_CONTESTS,
                element: (
                    <Suspense fallback={<Loading />}>
                        <EsAllContests />
                    </Suspense>
                ),
                errorElement: <ErrorBoundary />,
            },
            {
                path: ROUTES.ESJOIN_CONTESTS,
                element: (
                    <Suspense fallback={<Loading />}>
                        <ProtectedRoute>
                            <EsJoinContest />
                        </ProtectedRoute>
                    </Suspense>
                ),
                errorElement: <ErrorBoundary />,
            },
            {
                path: ROUTES.ESLIVE_CONTESTS,
                element: <EsLiveContests />,
                errorElement: <ErrorBoundary />,
            },
            {
                path: ROUTES.ESJURY_DUTY,
                element: (
                    <ProtectedRoute accessCheck accessType={E_USER_PERMISSIONS.JURY}>
                        <Suspense fallback={<JuryPageShimmer />}>
                            <EsJuryDuty />
                        </Suspense>
                    </ProtectedRoute>
                ),
                errorElement: <ErrorBoundary />,
            },
            {
                path: ROUTES.TALENTS,
                element: (
                    <ProtectedRoute accessCheck accessType={E_USER_PERMISSIONS.CASTING_DIRECTOR}>
                        <Suspense fallback={<Loading />}>
                            <Talents />
                        </Suspense>
                    </ProtectedRoute>
                ),
                errorElement: <ErrorBoundary />,
            },
            {
                path: ROUTES.ESCOMING_SOON,
                element: (
                    <Suspense fallback={<ComingSoonShimmer />}>
                        <EsComingSoon />
                    </Suspense>
                ),
                errorElement: <ErrorBoundary />,
            },
            {
                path: ROUTES.ESVIEW_CONTEST,
                element: (
                    <Suspense fallback={<ViewContestShimmer />}>
                        <EsViewContest />
                    </Suspense>
                ),
                errorElement: <ErrorBoundary />,
                action: () => {},
                loader: async () => {
                    return true;
                },
                caseSensitive: true,
                hasErrorBoundary: true,
            },
            {
                path: ROUTES.ESCONTEST_ENGAGE,
                element: (
                    <ProtectedRoute accessCheck accessType="admin">
                        <Suspense fallback={<ViewContestShimmer />}>
                            <EsContestEngage />
                        </Suspense>
                    </ProtectedRoute>
                ),
                errorElement: <ErrorBoundary />,
            },
            {
                path: ROUTES.ESMY_VIDEOS,
                element: (
                    <ProtectedRoute>
                        <Suspense fallback={<UserProjectsShimmer />}>
                            <UserProjects />
                        </Suspense>
                    </ProtectedRoute>
                ),
                errorElement: <ErrorBoundary />,
            },
            {
                path: ROUTES.ESMY_PROFILE,
                element: (
                    <ProtectedRoute>
                        <Suspense fallback={<EsMyProfileContainerLoadingState />}>
                            <EsMyProfile />
                        </Suspense>
                    </ProtectedRoute>
                ),
                errorElement: <ErrorBoundary />,
            },
            {
                path: ROUTES.USER,
                element: (
                    <ProtectedRoute>
                        <Suspense fallback={<EsMyProfileContainerLoadingState />}>
                            <EsMyProfile />
                        </Suspense>
                    </ProtectedRoute>
                ),
                errorElement: <ErrorBoundary />,
            },
            {
                path: ROUTES.SEARCH,
                element: (
                    <Suspense fallback={<SearchContainerShimmer />}>
                        <Search />
                    </Suspense>
                ),
                errorElement: <ErrorBoundary />,
            },
            {
                path: ROUTES.ESSETTINGS,
                element: (
                    <Suspense fallback={<Loading />}>
                        <ProtectedRoute>
                            <EsSettings />
                        </ProtectedRoute>
                    </Suspense>
                ),
                errorElement: <ErrorBoundary />,
            },
            {
                path: ROUTES.EVENT,
                element: (
                    <Suspense fallback={<ViewContestShimmer />}>
                        <Event />
                    </Suspense>
                ),
                errorElement: <ErrorBoundary />,
                action: () => {},
                loader: async () => {
                    return true;
                },
                caseSensitive: true,
                hasErrorBoundary: true,
            },
            {
                path: ROUTES.EVENT_CUSTOM,
                element: (
                    <Suspense fallback={<ViewContestShimmer />}>
                        <Event />
                    </Suspense>
                ),
                errorElement: <ErrorBoundary />,
                action: () => {},
                loader: async () => {
                    return true;
                },
                caseSensitive: true,
                hasErrorBoundary: true,
            },
        ],
    },
    {
        path: ROUTES.ESNEWPASSWORD,
        element: (
            <Suspense fallback={<Loading />}>
                <EsNewPassword />
            </Suspense>
        ),
        errorElement: <ErrorBoundary />,
    },
    {
        path: ROUTES.ESSIGNUP,
        element: (
            <Suspense fallback={<Loading />}>
                <EsSignup />
            </Suspense>
        ),
        errorElement: <ErrorBoundary />,
    },
    {
        path: ROUTES.ESRESETPASSWORD,
        element: (
            <Suspense fallback={<Loading />}>
                <EsResetPassword />
            </Suspense>
        ),
        errorElement: <ErrorBoundary />,
    },
    {
        path: ROUTES.VERIFY_EMAIL,
        element: (
            <Suspense fallback={<Loading />}>
                <VerifyEmail />
            </Suspense>
        ),
        errorElement: <ErrorBoundary />,
    },
    {
        path: ROUTES.ACCEPT_JURY_INVITE,
        element: (
            <Suspense fallback={<Loading />}>
                <JuryAcceptance />
            </Suspense>
        ),
        errorElement: <ErrorBoundary />,
    },
    {
        path: ROUTES.LINKS,
        element: (
            <Suspense fallback={<Loading />}>
                <Links />
            </Suspense>
        ),
        errorElement: <ErrorBoundary />,
    },
    {
        path: ROUTES.ESLOGIN,
        element: (
            <Suspense fallback={<Loading />}>
                <EsLogin />
            </Suspense>
        ),
        errorElement: <ErrorBoundary />,
    },
    {
        path: ROUTES.FAQ,
        element: (
            <Suspense fallback={<Loading />}>
                <FAQ />
            </Suspense>
        ),
        errorElement: <ErrorBoundary />,
    },
    {
        path: ROUTES.HELP_CENTER,
        element: (
            <Suspense fallback={<FooterPageShimmer />}>
                <HelpCenter />
            </Suspense>
        ),
        errorElement: <ErrorBoundary />,
    },
    {
        path: ROUTES.TERMS_OF_USE,
        element: (
            <Suspense fallback={<FooterPageShimmer />}>
                <TermsOfUse />
            </Suspense>
        ),
        errorElement: <ErrorBoundary />,
    },
    {
        path: ROUTES.SHOP,
        element: (
            <Suspense fallback={<Loading />}>
                <Shop />
            </Suspense>
        ),
        errorElement: <ErrorBoundary />,
    },
    {
        path: ROUTES.PRIVACY_POLICY,
        element: (
            <Suspense fallback={<FooterPageShimmer />}>
                <PrivacyPolicy />
            </Suspense>
        ),
        errorElement: <ErrorBoundary />,
    },
    {
        path: '*',
        element: (
            <Suspense fallback={<Loading />}>
                <ModernPageNotFound />
            </Suspense>
        ),
    },
]);
