import { makeStyles } from '@mui/styles';
import { theme } from '../../constants/theme';
import { FONT_ENUM } from '../../types/global/fonts.types';

export const useStyles = (shouldDisable: boolean) =>
    makeStyles({
        root: {
            '& .MuiAutocomplete-popupIndicator': {
                display: 'none',
            },
            '& .MuiChip-root': {
                backgroundColor: 'rgba(251, 188, 85, 1)',
            },
            '& .MuiAutocomplete-option[data-focus="true"]': {
                backgroundColor: 'transparent',
            },
            '& .MuiAutocomplete-input': {
                color: 'white', // Change text color to white
                '&::selection': {
                    backgroundColor: 'transparent', // Remove text highlight
                },
            },
            '& .MuiInputBase-root-MuiFilledInput-root': {
                color: 'white',
            },
            '& .MuiChip-label': {
                color: 'black', // Change the limit tag text color (you can use any color here)
                fontFamily: FONT_ENUM.REGULAR_HEADER, // Change the font family (you can use any desired font family here)
                fontSize: '14px', // Change the font size (you can use any desired font size here)
            },
            '& .MuiAutocomplete-tag': {
                fontSize: '14px', // Change the font size (you can use any desired font size here)
                fontWeight: 'normal', // Change the font weight (you can use 'bold', 'normal', etc.)
                fontStyle: 'normal', // Change the font style (you can use 'normal', 'italic', 'oblique', etc.)
                color: 'white',
                padding: '2px 8px',
            },
            '& .MuiAutocomplete-inputRoot': {
                border: '1px solid grey', // Add a default grey border
                borderRadius: '15px', // Optional: Add rounded corners to the border
                '&.Mui-focused': {
                    borderColor: 'rgba(251, 188, 85, 1)', // Change the border color to yellow when focused
                },
            },
            '& .Mui-focused .MuiAutocomplete-inputRoot': {
                borderColor: 'rgba(251, 188, 85, 1)', // Change the border color to yellow when focused
            },
        },
        inputRoot: {
            padding: '18px', // Apply padding inside the TextField for the chips
        },
        noOptions: {
            color: '#fff',
            backgroundColor: shouldDisable ? theme.dark.deep_grey : 'transparent',
            fontFamily: FONT_ENUM.REGULAR_HEADER,
            display: !shouldDisable ? 'none' : 'inherit',
        },
    });
