import { createSelector, Selector } from 'reselect';
import { RootState } from '../../redux/root-reducer';
import { IUserProfile } from '../../redux/user/actions';
import { IDashboardModel, IProject, ISubmission, TComment } from '../../types/global/helper';
import { ActivityResponse, SearchSuggestion } from '../../redux/activity/types';
import moment from 'moment';
import { FilterValueSet } from '../../redux/appConfigurator/types';
import { IActSummary } from '../../types/global/media.types';
import { IAudienceInitialState } from '../../redux/audience/model';
import { isEmpty } from '../../utils/lodash';
import { MediaType } from '../../redux/project/types';
import { projectSelector } from '../UserProjects/selector';
import { PLAYER_CONTROL } from '../../types/global/generic.types';

export const userProfileSelector: Selector<RootState, IUserProfile> = createSelector(
    (state: RootState) => state.user,
    user => user.userProfile,
);
export const externalProfileSelector = (username: string): Selector<RootState, IUserProfile | undefined> => {
    return createSelector(
        (state: RootState) => state.user,
        profile => (isEmpty(username) ? profile.userProfile : profile.externalUserProfile),
    );
};
export const isFetchingProfile: Selector<RootState, boolean> = createSelector(
    (state: RootState) => state.user,
    profile => profile.gettingExternalUserProfile,
);
export const isUpdatingProfile: Selector<RootState, boolean> = createSelector(
    (state: RootState) => state.user,
    profile => profile.updatingUserProfile,
);
export const isChangingPassword: Selector<RootState, boolean> = createSelector(
    (state: RootState) => state.user,
    profile => profile.changingPassword,
);
export const recentProjectSelector: Selector<RootState, IProject | undefined> = createSelector(
    (state: RootState) => state.project,
    project => project.userProjects[0],
);
export const userSubmissionSelector: Selector<RootState, ISubmission[] | undefined> = createSelector(
    (state: RootState) => state.contest,
    contest => contest.userSubmissions,
);
export const userDashboardSelector: Selector<RootState, IDashboardModel | undefined> = createSelector(
    (state: RootState) => state.account,
    account => account.dashboard,
);

export const suggestedSearchSelector: Selector<RootState, SearchSuggestion[]> = createSelector(
    (state: RootState) => state.activity,
    suggestionObject => suggestionObject.searchSuggestions,
);
export const myActivitySelector: Selector<RootState, ActivityResponse[]> = createSelector(
    (state: RootState) => state.activity,
    myActivityObject => myActivityObject.myActivities,
);

/** Get the activitues and sort it based on dates */
export const searchSuggestionsSelector: Selector<
    RootState,
    { suggestions: SearchSuggestion[]; myActivity: ActivityResponse[] }
> = createSelector([suggestedSearchSelector, myActivitySelector], (suggestions, myActivity) => {
    const sortedAndFilteredActivity =
        myActivity
            .filter(activity => activity.activity_type === 'initiated a search')
            .sort((a, b) => {
                const dateA: any = moment(a.createdAt);
                const dateB: any = moment(b.createdAt);
                return dateB - dateA;
            }) || 1;
    const indexOfDifferentSearchTermInActivity =
        sortedAndFilteredActivity.findIndex(
            e => e.metadata?.search_term !== sortedAndFilteredActivity[0].metadata?.search_term,
        ) || 1;
    const indexOfDifferentSearchTermInSuggestion = suggestions.findIndex(e => e._id !== suggestions[0]._id);
    const parsedActivity = sortedAndFilteredActivity.slice(0, 2);
    parsedActivity[parsedActivity.length - 1] = sortedAndFilteredActivity[indexOfDifferentSearchTermInActivity];
    const slicedSuggestion = suggestions.slice(0, 2);
    slicedSuggestion[suggestions.length - 1] = suggestions[indexOfDifferentSearchTermInSuggestion];
    return {
        suggestions: suggestions.slice(0, 2),
        myActivity: parsedActivity,
    };
});

export const selectedFilterSelector: Selector<RootState, FilterValueSet> = createSelector(
    (state: RootState) => state.appConfigurator,
    filters => filters.selectedFiltersValues,
);

export const isSelectedFilterEmptySelector: Selector<RootState, boolean> = createSelector(
    [selectedFilterSelector],
    selectedFilter => isEmpty(selectedFilter),
);

export const mediaPlayerSelector: Selector<RootState, boolean> = createSelector(
    (state: RootState) => state.project,
    project => !!project.selectedProject,
);

export const actPlayerSelector: Selector<RootState, boolean> = createSelector(
    (state: RootState) => state.project,
    project => !!project.selectedAct && project.selectedMediaType === 'act',
);

export const currentMediaTypeSelector: Selector<RootState, MediaType | undefined> = createSelector(
    (state: RootState) => state.project,
    project => project.selectedMediaType,
);

export const selectedProjectComents: Selector<RootState, TComment[]> = createSelector(
    (state: RootState) => state.project,
    project => project.selectedProjectComments,
);

export const selectedActSelector: Selector<RootState, IActSummary | undefined> = createSelector(
    (state: RootState) => state.project,
    project => project.selectedAct,
);

export const currentVideoSelector: Selector<RootState, IProject | null | undefined> = createSelector(
    (state: RootState) => state.project,
    project => project.selectedProject,
);

export const currentVideoDetailsSelector: Selector<RootState, IProject> = createSelector(
    (state: RootState) => state.project,
    project => project.selectedProjectDetails,
);

export const playlistSelector: Selector<RootState, IProject[] | undefined> = createSelector(
    (state: RootState) => state.project,
    project => project.playlist,
);

export const upnextPlaylistSelector: Selector<RootState, IProject[] | undefined> = createSelector(
    [projectSelector],
    project => project.upNextPlaylist,
);

export const similarPlaylistSelector: Selector<RootState, IProject[] | undefined> = createSelector(
    [projectSelector],
    project => project.similarPlaylist,
);

export const feedSelector: Selector<RootState, IProject[] | undefined> = createSelector(
    (state: RootState) => state.project,
    project => project.feed,
);

export const canLoadMoreFeedSelector: Selector<
    RootState,
    { page: number; canLoadMore: boolean; initialProjectId?: string }
> = createSelector(
    (state: RootState) => state.project,
    project => ({
        canLoadMore: project.canLoadMoreFeed,
        page: project.currentFeedPage,
        initialProjectId: project.initialProjectId,
    }),
);

export const currentSelectedVideoComments: Selector<RootState, TComment[] | undefined> = createSelector(
    (state: RootState) => state.project,
    project => project.selectedProjectComments,
);

export const mediaCommentsSelector: Selector<RootState, TComment[] | undefined> = createSelector(
    (state: RootState) => state.project,
    project => project.selectedProjectComments,
);
/** Change */
export const mediaPlayerTypeSelector: Selector<RootState, boolean> = createSelector(
    (state: RootState) => state.project,
    project => !!project.selectedProject,
);

export const showToastSelector: Selector<RootState, boolean | undefined> = createSelector(
    (state: RootState) => state.appConfigurator,
    toastStatus => toastStatus.showToast,
);
export const toastMessageSelector: Selector<RootState, string | undefined> = createSelector(
    (state: RootState) => state.appConfigurator,
    toastStatus => toastStatus.toastMessage,
);

export const toastSelector: Selector<RootState, { open?: boolean; message?: string }> = createSelector(
    [showToastSelector, toastMessageSelector],
    (toastState, toastMessage) => ({
        open: toastState,
        message: toastMessage,
    }),
);

export const audienceSelector: Selector<RootState, IAudienceInitialState> = createSelector(
    (state: RootState) => state.audience,
    audience => audience,
);

export const followingSelector: Selector<RootState, boolean | undefined> = createSelector(
    [audienceSelector],
    audience => audience.followingInProgress,
);

export const verticalPlayerSelector: Selector<RootState, boolean> = createSelector(
    [projectSelector],
    project => project.verticalPlayer === PLAYER_CONTROL.ON,
);

export const horizontalPlayerSelector: Selector<RootState, boolean> = createSelector(
    [projectSelector],
    project => project.horizontalPlayer === PLAYER_CONTROL.ON,
);
