export const en_config = {
    /** Toggle this on to use the server locally */
    IS_LOCAL: false,
    __DEV__: true,
    TWO_FA_LENGTH: 6,
    IS_BETA: true,
    APP_VERSION: '1.0.0',
    WIP: false,
    CACHED_DURATION: 30 * 60 * 1000, // TO 30 minutes
    /** Controls if we disable caching and use direct api calls */
    USE_CACHE: false,
    /** Determines how much response we fetch for each call */
    RESULT_LIMIT: 50,
    /** Macimum file size we support in upload */
    MAX_SIZE: 322428800,
    MOBILE_MAX_SIZE: 113246208,
    /** The below will serve as a default media control */
    MIN_SIZE: 1000000,
    MEDIA_CONFIG: {
        file_size_limit: 322428800,
        mobile_size_limit: 113246208,
        image_size_limit: 10485760, //10mb
        pagination_count: 10,
        supported_file_formats: [
            {
                base: 'video/mp4',
                extension: ['.hevc', '.mp4', '.mov', '.m4v', '.avi'],
            },
            {
                base: 'image/jpeg',
                extension: ['.jpg', '.png'],
            },
        ],
        // fileSize > 1000KB
        compressionQualityHigh: 0.3,
        // fileSize > 500KB
        compressionQualityMid: 0.4,
        // fileSize < 500KB
        compressionQualityLow: 0.5,
    },
};
