import React from 'react';
import { StyleSheet } from 'react-native';
import { Typography, Box, Stack, SxProps, Theme } from '@mui/material';
import { useThemeContext } from '../../hook/useThemeManager';
import { ColorScheme, theme } from '../../constants/theme';
import { FONT_ENUM } from '../../types/global/fonts.types';
import { convertMinuteSecondsDays, createFullName } from '../../utils/AppUtils';
import { IProjectSummary } from '../../types/global/media.types';
import { EsPlayButton } from '../EsButtons/EsPlayButton';
import CustomLazyLoadImage from '../Image/CustomLazyLoadImage';
import { IUserSummary } from '../../types/global/helper';
import { ThspianAvatar } from '../Avatar/ThspianAvatar';

export interface IMediaCard {
    media: IProjectSummary;
    onMediaCardPressed?: (project: IProjectSummary) => void;
    isDetailsOnly?: boolean;
    sx?: SxProps<Theme>;
}

const MediaCard: React.FC<IMediaCard> = ({ media, onMediaCardPressed, isDetailsOnly, sx }) => {
    const { themeStyle } = useThemeContext();
    const styles = dynamicStyles(themeStyle);

    return (
        <Box sx={{ position: 'relative', mb: 5 }}>
            <Box
                onClick={() => onMediaCardPressed && onMediaCardPressed(media)}
                sx={{
                    width: '100%',
                    height: '200px',
                    position: 'relative',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    justifyContent: 'flex-end',
                    borderRadius: {
                        xs: 0,
                        sm: '10px',
                    },
                    border: 'none',
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center',
                    textDecoration: 'none',
                    cursor: 'pointer',
                    overflow: 'hidden',
                    ...sx,
                }}
            />
            <CustomLazyLoadImage
                src={media.thumbnail ? media.thumbnail : media.feature_image}
                style={{
                    width: '100%',
                    height: '200px',
                    backgroundSize: 'contain',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    objectFit: 'cover',
                    zIndex: -1,
                    borderRadius: '16px',
                }}
            />
            {isDetailsOnly && (
                <Box
                    sx={{
                        height: '100%',
                        width: '100%',
                        position: 'relative',
                    }}>
                    {isDetailsOnly && <EsPlayButton onClick={() => onMediaCardPressed && onMediaCardPressed(media)} />}
                </Box>
            )}
            {isDetailsOnly && (
                <Box sx={{ gap: '2px', marginLeft: 1, position: 'absolute', top: 0, left: 0 }}>
                    <Typography style={styles.title}>{media.title}</Typography>
                </Box>
            )}
            {!isDetailsOnly && (
                <Stack
                    direction="row"
                    alignItems="flex-start"
                    mt={1.5}
                    px={{
                        xs: 2,
                        sm: 0,
                    }}>
                    <ThspianAvatar
                        user={media.created_by as unknown as IUserSummary}
                        badgeSx={{
                            border: `1px solid ${theme[themeStyle].tag_white}`,
                            borderRadius: '50%',
                        }}
                    />
                    <Box style={styles.flexColumn} sx={{ gap: '2px', marginLeft: 1 }}>
                        <Typography
                            style={styles.title}
                            sx={{
                                display: '-webkit-box',
                                overflow: 'hidden',
                                WebkitBoxOrient: 'vertical',
                                WebkitLineClamp: 2,
                            }}>
                            {media.title}
                        </Typography>
                        <Typography style={styles.subtitle}>{createFullName(media.created_by)}</Typography>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Typography style={styles.date}>
                                {media.view_count} View{`${media.view_count && media.view_count > 1 ? 's' : ''}`}
                            </Typography>
                            <hr
                                style={{
                                    width: '4px',
                                    height: '4px',
                                    borderRadius: 100,
                                    marginRight: '4px',
                                    marginLeft: '4px',
                                    backgroundColor: theme[themeStyle].form_label,
                                }}
                            />
                            <Typography style={styles.date}>{convertMinuteSecondsDays(media.createdAt)} ago</Typography>
                        </Box>
                    </Box>
                </Stack>
            )}
        </Box>
    );
};

const dynamicStyles = (colorScheme: ColorScheme) =>
    StyleSheet.create({
        bgImage: {
            position: 'absolute',
            top: 0,
            bottom: 0,
            right: 0,
            left: 0,
            width: 'inherit',
            height: 'inherit',
            zIndex: -1,
        },
        flexColumn: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            justifyContent: 'center',
            flex: 1,
        },
        title: {
            fontSize: 16,
            fontWeight: '600',
            fontFamily: FONT_ENUM.MAIN_BOLD,
            textAlign: 'left',
            color: theme[colorScheme].filters_white,
        },
        subtitle: {
            fontSize: 12,
            fontWeight: '500',
            fontFamily: FONT_ENUM.MAIN_MEDIUM,
            textAlign: 'left',
            color: theme[colorScheme].background_gray,
            textTransform: 'capitalize',
        },
        date: {
            fontSize: 10,
            fontWeight: '500',
            fontFamily: FONT_ENUM.MAIN_MEDIUM,
            textAlign: 'left',
            color: theme[colorScheme].background_gray,
        },
    });

export { MediaCard };
