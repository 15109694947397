import * as React from 'react';
import dayjs from 'dayjs';
import { DemoItem } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Box, SxProps, Theme, styled } from '@mui/material';
import { useThemeContext } from '../../hook/useThemeManager';
import { theme } from '../../constants/theme';

export interface ICustomDatePicker {
    label?: string;
    value?: string;
    onChange?: (e: any) => void;
    minDate?: any;
    maxDate?: any;
    defaultValue?: any;
    ageLimit?: number;
    sx?: SxProps<Theme>;
}

const StyledBox = styled(Box)(({ theme }) => ({
    width: '100%',
    border: '1px solid grey',
    borderRadius: '10px',
    overflow: 'hidden',
    color: 'white', // Set the text color to white
    backgroundColor: 'rgba(19, 21, 23, 1)',
    '&:focus-within': {
        border: `1px solid rgba(251, 188, 85, 1)`, // Set the border to yellow on focus
    },
}));

export const CustomDatePicker: React.FC<ICustomDatePicker> = ({
    label,
    value,
    onChange,
    minDate,
    maxDate,
    defaultValue,
    sx,
}) => {
    const { themeStyle } = useThemeContext();

    return (
        <StyledBox sx={sx}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoItem label={label}>
                    <DatePicker
                        defaultValue={defaultValue}
                        minDate={minDate}
                        onChange={onChange}
                        value={dayjs(value)}
                        maxDate={maxDate}
                        slotProps={{
                            textField: {
                                variant: 'filled',
                                inputProps: {
                                    style: {
                                        color: theme[themeStyle].lightWhite,
                                        backgroundColor: theme[themeStyle].gray_text,
                                        paddingTop: 0,
                                        paddingBottom: 0,
                                        height: '50px',
                                    },
                                },
                                InputProps: {
                                    disableUnderline: true,
                                },
                            },
                            layout: {
                                sx: {
                                    backgroundColor: theme[themeStyle].gray_text,
                                    color: theme[themeStyle].lightWhite,
                                    '& .MuiDayCalendar-weekDayLabel': {
                                        color: `${theme[themeStyle].light_gray_text}`,
                                    },
                                },
                            },
                            nextIconButton: {
                                sx: {
                                    color: theme[themeStyle].primary_orange,
                                    ':disabled': {
                                        color: `${theme[themeStyle].light_gray_text} !important`,
                                    },
                                },
                            },
                            previousIconButton: {
                                sx: {
                                    color: theme[themeStyle].primary_orange,
                                    ':disabled': {
                                        color: `${theme[themeStyle].light_gray_text} !important`,
                                    },
                                },
                            },
                            day: {
                                sx: {
                                    color: theme[themeStyle].lightWhite,
                                    ':disabled': {
                                        color: `${theme[themeStyle].light_gray_text} !important`,
                                    },
                                    headerColor: 'white !important',
                                },
                            },
                            switchViewButton: {
                                sx: {
                                    color: theme[themeStyle].primary_orange,
                                },
                            },
                            openPickerButton: {
                                sx: {
                                    color: theme[themeStyle].lightWhite,
                                },
                            },
                        }}
                    />
                </DemoItem>
            </LocalizationProvider>
        </StyledBox>
    );
};
