import * as React from 'react';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import { useThemeContext } from '../../hook/useThemeManager';
import { theme } from '../../constants/theme';
import { Container, Typography, IconButton } from '@mui/material';
import { PlayArrow, Cancel as CancelIcon, Pause as PauseIcon } from '@mui/icons-material';
import { FONT_ENUM } from '../../types/global/fonts.types';
import { makeStyles } from '@mui/styles';
import { isEmpty } from '../../utils/lodash';

const useStyles = makeStyles(() => ({
    progressBar: {
        transition: 'top 1s ease-in-out',
    },
}));

export interface IProgressBar {
    progress?: number;
    handleOnClose?: () => void;
    onCancel?: () => void;
    uploadMessage?: string;
    onResume?: () => void;
    onPause?: () => void;
    isPaused?: boolean;
}

export const ProgressBar: React.FC<IProgressBar> = ({
    progress,
    handleOnClose,
    onCancel,
    uploadMessage,
    onResume,
    isPaused,
    onPause,
}) => {
    const { themeStyle, device } = useThemeContext();
    const classes = useStyles();

    React.useEffect(() => {
        return () => {
            if (progress && progress >= 100) {
                handleOnClose && handleOnClose();
            }
        };
    }, [handleOnClose, progress]);

    const mobileProgress = (
        <Container
            disableGutters
            style={{
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                overflow: 'hidden',
                width: '100%',
                maxWidth: '100%',
            }}
            sx={{
                mb: { xs: '10px', sm: '10px' },
                marginLeft: { xs: '0px', sm: '15px' },
                paddingLeft: { xs: '0px', sm: '40px' },
                paddingRight: { xs: '0px', sm: '16px' },
                overflowX: 'hidden',
            }}>
            <Box
                sx={{
                    width: '100%',
                    position: 'relative',
                    borderRadius: 3,
                }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography
                        sx={{
                            fontFamily: FONT_ENUM.REGULAR_HEADER,
                            color: theme[themeStyle].tag_white,
                            fontSize: 12,
                        }}>
                        {isPaused ? 'Upload Paused!' : 'Uploading ... Do NOT refresh.'}
                    </Typography>
                    {
                        <Box>
                            {isPaused ? (
                                <IconButton
                                    aria-label="resume upload"
                                    color="primary"
                                    onClick={onResume}
                                    sx={{ mr: 0.5 }}>
                                    <PlayArrow />
                                </IconButton>
                            ) : (
                                <IconButton
                                    aria-label="pause upload"
                                    color="primary"
                                    onClick={onPause}
                                    sx={{ mr: 0.5 }}>
                                    <PauseIcon />
                                </IconButton>
                            )}
                            <IconButton aria-label="cancel upload" color="secondary" onClick={onCancel}>
                                <CancelIcon />
                            </IconButton>
                        </Box>
                    }
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Box sx={{ width: '100%', mr: 1 }}>
                        <LinearProgress
                            variant="determinate"
                            value={Number(progress)}
                            sx={{ borderRadius: 2, color: theme[themeStyle].main_blue }}
                            className={`${classes.progressBar}`}
                        />
                    </Box>
                    <Box sx={{ minWidth: 35 }}>
                        <Typography
                            style={{
                                fontFamily: FONT_ENUM.MAIN_MEDIUM,
                                color: theme[themeStyle].tag_white,
                                fontSize: 12,
                            }}>{`${Math.round(progress as number)}%`}</Typography>
                    </Box>
                </Box>
            </Box>
        </Container>
    );
    return !device.isDesktop ? (
        mobileProgress
    ) : (
        <Container
            disableGutters
            style={{
                height: 'auto',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                overflow: 'hidden',
                width: 'auto',
                maxWidth: '100%',
            }}
            sx={{
                marginTop: { xs: '0', sm: '40px' },
                mb: { xs: '10px', sm: '0' },
                marginLeft: { xs: '0px', sm: '65px' },
                paddingLeft: { xs: '16px', sm: '40px' },
                paddingRight: { xs: '16px', sm: '40px' },
                overflowX: 'hidden',
            }}>
            <Box
                sx={{
                    width: '100%',
                    border: `0.1px solid ${theme[themeStyle].darkGray60}`,
                    position: 'relative',
                    borderRadius: 3,
                    padding: 3,
                }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography
                        sx={{
                            fontFamily: FONT_ENUM.REGULAR_HEADER,
                            color: theme[themeStyle].tag_white,
                            fontSize: 12,
                        }}>
                        {!isEmpty(uploadMessage)
                            ? uploadMessage
                            : 'Uploading Project In Progress ... Do NOT refresh page.'}
                    </Typography>
                    {
                        <Box display="flex">
                            {isPaused ? (
                                <IconButton
                                    aria-label="resume upload"
                                    color="primary"
                                    onClick={onResume}
                                    sx={{ mr: 0.5 }}>
                                    <PlayArrow />
                                </IconButton>
                            ) : (
                                <IconButton
                                    aria-label="pause upload"
                                    color="primary"
                                    onClick={onPause}
                                    sx={{ mr: 0.5 }}>
                                    <PauseIcon />
                                </IconButton>
                            )}
                            <IconButton aria-label="cancel upload" color="secondary" onClick={onCancel}>
                                <CancelIcon />
                            </IconButton>
                        </Box>
                    }
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
                    <Box sx={{ width: '100%', mr: 1 }}>
                        <LinearProgress
                            variant="determinate"
                            value={Number(progress)}
                            sx={{ borderRadius: 2, color: theme[themeStyle].main_blue }}
                            className={`${classes.progressBar}`}
                        />
                    </Box>
                    <Box sx={{ minWidth: 35 }}>
                        <Typography
                            style={{
                                fontFamily: FONT_ENUM.MAIN_MEDIUM,
                                color: theme[themeStyle].tag_white,
                                fontSize: 12,
                            }}>{`${Math.round(progress as number)}%`}</Typography>
                    </Box>
                </Box>
            </Box>
        </Container>
    );
};
