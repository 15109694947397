export enum ROUTES {
    ABOUT = '/about',
    WINNERS = '/winners',
    ACCOUNT = '/account',
    COMING_SOON = '/comingsoon',
    DISCOVER = '/discover',
    HOME = '/',
    USER = '/:profileId?',
    SUBMIT = '/submit',
    EXCEPTION = '*',
    API_LOADER = '/api',
    LOGIN_IN = '/login',
    REGISTER = '/register',
    PHONE_AUTHENTICATION = '/verify',
    DASHBOARD = '/dashboard',
    FORGOT_PASSWORD = '/forgotPassword',
    SETTINGS = '/settings',
    PROFILE = '/profile',
    SPLASH = '/splash',
    LANDING = '/landing',
    DASHBOARD_EVENTS = '/dashboard/events',
    DASHBOARD_JURYDUTY = '/dashboard/jury',
    DASHBOARD_PROFILE = '/dashboard/profile',
    DASHBOARD_PROJECT = '/dashboard/project',
    DASHBOARD_SETTINGS = '/dashboard/settings',
    DASHBOARD_SUBMISSIONS = '/dashboard/submissions',
    DASHBOARD_TEAMS = '/dashboard/teams',
    DASHBOARD_HELP = '/dashboard/help',
    DASHBOARD_MAIN = '/dashboard/main',
    ESLOGIN = '/login',
    ESSIGNUP = '/signup',
    ESRESETPASSWORD = '/reset-password',
    ESNEWPASSWORD = '/new-password/:token?',
    ESDISCOVERY = '/discovery',
    ESVIEW_CONTEST = '/contest/:contestId?',
    EVENT = '/event/:contestId?',
    EVENT_CUSTOM = '/:hostName/:contestId?',
    ESTRENDING = '/trending',
    ESCONTEST = '/submissions',
    ESMY_VIDEOS = '/projects',
    ESCOMING_SOON = '/coming-soon',
    ESMY_PROFILE = '/:profileId?',
    ESJURY_DUTY = '/jury-duty',
    ESWATCH_VIDEO = '/watch',
    ESALL_CONTESTS = '/esall-contests',
    ESJOIN_CONTESTS = '/esjoin-contest',
    ESLIVE_CONTESTS = '/eslive-contests',
    ESCONTEST_ENGAGE = '/contest-metrics/:contestId?',
    ESSETTINGS = '/settings',
    TERMS_OF_USE = '/terms-of-use',
    FAQ = '/faq',
    HELP_CENTER = '/help-center',
    SHOP = '/shop',
    PRIVACY_POLICY = '/privacy-policy',
    COOKIE_PREFERENCE = '/cookie-preference',
    SEARCH = '/search',
    VERIFY_EMAIL = '/email-verify/:token',
    ACCEPT_JURY_INVITE = '/jury/invite/:token',
    LINKS = '/links',
    TALENTS = '/talents',
}

export enum ROUTE_NAMES {
    ABOUT = 'about',
    WINNERS = 'winners',
    ACCOUNT = 'account',
    COMING_SOON = 'comingsoon',
    HOME = '',
    SUBMIT = 'submit',
    EXCEPTION = '*',
    API_LOADER = 'api',
    LOGIN_IN = 'login',
    REGISTER = 'register',
    PHONE_AUTHENTICATION = 'verify',
    DASHBOARD = 'dashboard',
    FORGOT_PASSWORD = 'forgotPassword',
    SETTINGS = 'settings',
    PROFILE = 'profile',
    SEARCH = 'search',
    LINKS = 'links',
}

export const APP_ROUTES = {
    ABOUT: '/about',
    WINNERS: '/winners',
    ACCOUNT: '/account',
    COMING_SOON: '/comingsoon',
    DISCOVER: '/discover',
    HOME: '/',
    USER: '/:profileId?',
    SUBMIT: '/submit',
    EXCEPTION: '*',
    API_LOADER: '/api',
    LOGIN_IN: '/login',
    REGISTER: '/register',
    PHONE_AUTHENTICATION: '/verify',
    DASHBOARD: '/dashboard',
    FORGOT_PASSWORD: '/forgotPassword',
    SETTINGS: '/settings',
    PROFILE: '/profile',
    SPLASH: '/splash',
    LANDING: '/landing',
    DASHBOARD_EVENTS: '/dashboard/events',
    DASHBOARD_JURYDUTY: '/dashboard/jury',
    DASHBOARD_PROFILE: '/dashboard/profile',
    DASHBOARD_PROJECT: '/dashboard/project',
    DASHBOARD_SETTINGS: '/dashboard/settings',
    DASHBOARD_SUBMISSIONS: '/dashboard/submissions',
    DASHBOARD_TEAMS: '/dashboard/teams',
    DASHBOARD_HELP: '/dashboard/help',
    DASHBOARD_MAIN: '/dashboard/main',
    ESLOGIN: '/login',
    ESSIGNUP: '/signup',
    ESRESETPASSWORD: '/reset-password',
    ESNEWPASSWORD: '/new-password/:token?',
    ESDISCOVERY: '/discovery',
    ESVIEW_CONTEST: '/contest/:contestId?',
    ESTRENDING: '/trending',
    ESCONTEST: '/submissions',
    ESMY_VIDEOS: '/projects',
    ESCOMING_SOON: '/coming-soon',
    ESMY_PROFILE: '/:profileId?',
    ESJURY_DUTY: '/jury-duty',
    ESWATCH_VIDEO: '/watch',
    ESALL_CONTESTS: '/esall-contests',
    ESJOIN_CONTESTS: '/esjoin-contest',
    ESLIVE_CONTESTS: '/eslive-contests',
    ESCONTEST_ENGAGE: '/contest-metrics/:contestId',
    ESSETTINGS: '/settings',
    TERMS_OF_USE: '/terms-of-use',
    FAQ: '/faq',
    HELP_CENTER: '/help-center',
    SHOP: '/shop',
    PRIVACY_POLICY: '/privacy-policy',
    COOKIE_PREFERENCE: '/cookie-preference',
    SEARCH: '/search',
    VERIFY_EMAIL: '/email-verify',
    LINKS: '/links',
    TALENTS: '/talents',
};
