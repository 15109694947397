/* eslint-disable import/no-cycle */
import { configureStore } from '@reduxjs/toolkit';
import { PersistConfig, createTransform, persistReducer, persistStore } from 'redux-persist';
import createSagaMiddleware from 'redux-saga';
import { all } from 'redux-saga/effects';
import { reducer } from './root-reducer';
import { router } from '../Routes';
import accountSaga from './account/saga';
import activitySaga from './activity/saga';
import adopterSaga from './adopter/saga';
import auditiopnsSaga from './audition/saga';
import authenticationSaga from './authentication/saga';
import cacheSaga from './cache/saga';
import comingSoonSaga from './comingSoon/saga';
import configurationSaga from './configuration/saga';
import contestSaga from './contest/saga';
import discoverySaga from './discovery/saga';
import festivalSaga from './festival/saga';
import jurySaga from './jury/saga';
import liveSaga from './live/saga';
import localforage from 'localforage';
import navigationSaga from './navigator/saga';
import notificationsSaga from './notifications/saga';
import projectsSaga from './project/saga';
import reportSaga from './report/saga';
import sagaMonitor from './sagaMonitor';
import splashSaga from './splash/sagas';
import subscriptionSaga from './subscription/saga';
import talentProfileSaga from './talentProfile/saga';
import tokenRefreshSaga from './refreshToken/saga';
import userSaga from './user/sagas';
import viewsSaga from './view/saga';
import votesSaga from './vote/saga';
import audienceSaga from './audience/saga';
import requestSaga from './requests/saga';
import linkSaga from './links/saga';
import talentSaga from './talent/saga';
import attendanceSaga from './attendance/saga';
import advertSaga from './adverts/saga';
import appConfiguratorSaga from './appConfigurator/saga';
import uploadSaga from './upload/saga';

const Flatted = require('flatted');

const effectsMiddleware = (next: any) => (effect: any) => {
    if (effect && effect.type === 'PUT') {
        const { action } = effect.payload;
        const status = action.payload && action.payload.status;
        if (status === 401) {
            const effect401 = {
                ...effect,
                payload: {
                    ...effect.payload,
                    action: {
                        ...action,
                        meta: { errorStatus: 401 },
                    },
                },
            };
            return next(effect401);
        }
        return next(effect);
    }
    return next(effect);
};

const EXCLUDED_TYPES = ['LOGIN', 'GET_2FA_CODE_FOR_LOGIN', 'GET_2FA_CODE_FAILED', 'LOGIN_FAILED'];
const errorMiddleWare = (store: any) => (next: any) => (action: any) => {
    if (action.meta && action.meta.errorStatus && action.meta.errorStatus === 401) {
        const { refreshingToken } = store.getState().tokenRefresh;
        if (action.type === 'REFRESH_TOKEN' && refreshingToken) {
            return;
        }
        if (action.type !== 'REFRESH_TOKEN' && !refreshingToken && !EXCLUDED_TYPES.includes(action.type)) {
            //TODO: TEST MORE
            store.dispatch({ type: 'REFRESH_TOKEN' });
            return;
        }
        return;
    }
    next(action);
};

export const transformCircular = createTransform(
    inboundState => Flatted.stringify(inboundState),
    outboundState => Flatted.parse(outboundState),
);

const persistConfig: PersistConfig<any> = {
    key: 'root',
    storage: localforage,
    blacklist: ['live'],
    transforms: [transformCircular],
};

const persistedReducer = persistReducer(persistConfig, reducer);

/** The error context was added here to try context API with redux, but doesn't work and can be removed */
const sagaMiddleWare = createSagaMiddleware({
    sagaMonitor,
    effectMiddlewares: [effectsMiddleware],
    context: {
        router: router,
    },
});

const store: any = configureStore({
    reducer: persistedReducer,
    middleware: getDefaultMiddleware => {
        return getDefaultMiddleware({
            thunk: false,
            serializableCheck: false,
            immutableCheck: false,
        }).prepend([sagaMiddleWare, errorMiddleWare]);
    },
    devTools: process.env.NODE_ENV !== 'production',
});

export default function* rootSaga() {
    yield all([
        accountSaga(),
        activitySaga(),
        adopterSaga(),
        attendanceSaga(),
        advertSaga(),
        appConfiguratorSaga(),
        attendanceSaga(),
        audienceSaga(),
        auditiopnsSaga(),
        authenticationSaga(),
        cacheSaga(),
        comingSoonSaga(),
        configurationSaga(),
        contestSaga(),
        discoverySaga(),
        festivalSaga(),
        jurySaga(),
        linkSaga(),
        liveSaga(),
        navigationSaga(),
        notificationsSaga(),
        projectsSaga(),
        splashSaga(),
        subscriptionSaga(),
        talentProfileSaga(),
        tokenRefreshSaga(),
        uploadSaga(),
        userSaga(),
        reportSaga(),
        viewsSaga(),
        votesSaga(),
        requestSaga(),
        talentSaga(),
    ]);
}

export const persistor = persistStore(store);

sagaMiddleWare.run(rootSaga);

export { store };
